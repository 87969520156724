import {map, fromPairs, merge} from 'ramda';

export const LOG_DEBUG = 0x0001;
export const LOG_API = 0x0002;
export const LOG_ACCOUNTS = 0x0004;
export const LOG_REQUESTHANDLER = 0x0008;
export const LOG_ALL = 0xffff;

const LOG_FILTER = 0; // 0 for no logging
const LOG_LEVELS = ['log', 'info', 'debug', 'warn', 'error'];

export const createLogger = (prefix, category) => {
  const noop = () => {};
  const defaults = {log: noop, debug: noop, info: noop, warn: noop, error: noop};
  if (LOG_FILTER & category && LOG_LEVELS.length > 0) {
    if (process.env.NODE_ENV === 'production') {
      console.warn(`Logger '${prefix}' ${LOG_LEVELS} enabled in production. Are you sure about this?`)
    }
    const loggers = fromPairs(map(level => [level, console[level].bind(null, `[${prefix}]`)], LOG_LEVELS));
    return merge(defaults, loggers);
  }
  return defaults;
}
