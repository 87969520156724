import {extendObservable, decorate, action, runInAction} from 'mobx';
import {contacts, setContacts, submit} from '../api/Contact';
import {adjust, assoc} from 'ramda';

const emptyContact = {topic: '', name: '', email: '', number: ''};

class ContactStore {
  constructor(api, admin) {
    extendObservable(this, {
      message: '',
      contacts: [],
      editContacts: [],
      error: '',
      success: ''
    });

    this.admin = admin;
    this.api = api;

    this.requestContacts();
  }

  // API calls

  requestContacts = () => {
    const onContacts = action((payLoad) => {
      this.error = '';
      this.success = '';
      this.contacts = payLoad;
    });
    contacts(this.api, onContacts, this.onError);
  }

  requestSetContacts = () => {
    const validContacts = this.editContacts.filter((c) => c.topic || c.name || c.email || c.number);
    const onSetContacts = action(() => {
      this.contacts = validContacts;
      this.editContacts = [];
      this.setStatus('', 'Contacts updated');
    });
    setContacts(this.api, validContacts, onSetContacts, this.onError);
  }

  requestSubmit = () => {
    const onSubmit = action(() => {
      this.setStatus('', 'Message sent');
      this.message = '';
    });
    submit(this.api, '', '', this.message, onSubmit, this.onError);
  }

  onError = (error) => {
    this.setStatus(error.msg);
  }

  setStatus = (error, success) => {
    this.error = error || '';
    this.success = success || '';
  }

  // store handling

  setMessage = (message) => {
    this.message = message;
    if (message) {
      this.setStatus();
    }
  }

  updateContact = (index, key, value) => {
    if (this.admin && this.editContacts[index]) {
      this.editContacts = adjust(assoc(key, value), index, this.editContacts);
    }
  }

  deleteContact = (index) => {
    if (this.admin && this.editContacts[index]) {
      if (this.editContacts.length > 1) {
        this.editContacts.splice(index, 1);
      }
      else {
        this.editContacts = [emptyContact];
      }
    }
  }

  addContact = () => {
    this.editContacts.push(emptyContact);
  }

  // special setters/getters

  set editMode(value) {
    runInAction(() => {
      if (value) {
        this.editContacts = this.contacts.length > 0 ? this.contacts : [emptyContact];
      }
      else {
        this.editContacts = [];
      }
      this.setStatus();
    });
  }
  get editMode() {
    return this.admin && this.editContacts.length > 0;
  }
}

decorate(ContactStore, {
  requestContacts: action,
  requestSetContacts: action,
  requestSubmit: action,

  onError: action,
  setStatus: action,

  setMessage: action,
  updateContact: action,
  deleteContact: action,
  addContact: action
})

export default ContactStore;
