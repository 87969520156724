import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Container, Row, Col, Button} from 'reactstrap';
import NewsItem from './NewsItem';
import PostEditor from './editor/PostEditor';
import StatusMessage from '../../components/StatusMessage';
import ContentWrapper from '../ContentWrapper';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import NewsStore from '../../stores/NewsStore';
import MonthPicker from './MonthPicker';
import PostPicker from './PostPicker';
import moment from 'moment';

const Posts = observer(({store}) => {
  if (store.posts.length === 0) {
    const style = {
      width: '100%',
      height: window.innerHeight
    }
    return (
      <div style={style}/>
    );
  }

  const type = store.posts.length === 1 ? 'single' : 'archive';
  return store.posts.map((post) => {
    return (
      <NewsItem key={post.id} type={type} {...post} store={store}/>
    );
  });
})

const NewButton = observer(({store}) => {
  if (store.admin) {
    return (
      <Row>
        <Col>
          <Button
            color='primary'
            className='mb-4 btn-sm'
            onClick={() => store.createPost()}>New post</Button>
        </Col>
      </Row>
    );
  }
  return null;
})

const ShowAllButton = observer(({store}) => {
  if (store.showAllButton) {
    return (
      <div className='text-right'>
        <Button className='m-1 btn-sm' color='primary' onClick={() => store.showAll()}>Show all posts</Button>
      </div>
    );
  }
  return null;
})

class News extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    admin: PropTypes.bool.isRequired
  }

  constructor({api, admin}) {
    super();
    this.store = new NewsStore(api, admin, () => moment().unix());
  }

  componentWillUnmount() {
    this.store.unmount();
  }

  render() {
    const {error, success} = this.store;
    return (
      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <StatusMessage error={error} success={success}/>
            </Col>
          </Row>
          <NewButton store={this.store}/>
          <Row>
            <Col md={8} className='news-list'>
              <Posts store={this.store}/>
              <ShowAllButton store={this.store}/>
            </Col>
            <Col md={4} className='sidebar-list'>
              <div className='sticky-top'>
                <PostPicker store={this.store}/>
                <MonthPicker store={this.store}/>
              </div>
            </Col>
          </Row>
          <PostEditor newsStore={this.store}/>
        </Container>
        <ConfirmationDialog
          title='Delete post'
          msg={`Are you sure to delete post '${this.store.deletePost.title}'?`}
          isOpen={!!this.store.deletePost.id}
          onOk={() => this.store.requestDelete()}
          onCancel={() =>this.store.setDeletePost(null)}/>
      </ContentWrapper>
    );
  }
}

export default observer(News);
