import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Container, Row, Col, Button, Table, Input} from 'reactstrap';
import AccountsStore from '../../stores/AccountsStore';
import StatusMessage from '../../components/StatusMessage';

const Consent = ({mailing, privacy}) => (
  <React.Fragment>
    {privacy ? <span className='mx-1 fas fa-check-circle'></span> : null }
    {mailing ? <span className='mx-1 fas fa-mail-bulk'></span> : null }
  </React.Fragment>
)

const Stats = observer(({total, privacy, mailing, privacyp, mailingp}) => (
  <div className='text-right mt-2'>
    Total users: {total}<br/>
    GDPR accepted: {privacy} ({privacyp}%)<br/>
    Mailing allowed: {mailing} ({mailingp}%)<br/>
  </div>
))

const Tier = observer(({tier, onTierClick}) => {
  const onClick = (delta) => () => onTierClick(delta);
  const editMode = !!onTierClick;

  const TierButton = observer(({visible, delta, symbol}) => {
    if (editMode && visible) {
      return (<Button onClick={onClick(delta)}><span className={`fas fa-${symbol}`}/></Button>);
    }
    return null;
  })
  TierButton.defaultProps = {visible: true};

  const TierContent = observer(() => {
    if (tier < 1) {
      return editMode ? null : (<span>&mdash;</span>);
    }
    return (<span className={`tier tier-${tier}`}>{tier}</span>);
  })

  return (
    <td className='text-center'>
      <TierButton visible={tier > 0} delta={-1} symbol={'minus'}/>
      <TierContent/>
      <TierButton delta={1} symbol={'plus'}/>
    </td>
  );
})

const TableRow = observer(({user, myUsername, onClick, onTierClick}) => {
  const {username} = user;
  const className = username === myUsername ? 'text-info font-weight-bold' : '';

  return (
    <tr key={username} style={{cursor: onTierClick ? 'default' : 'pointer'}} onClick={onTierClick ? null : onClick}>
      <td className={className}>{username}</td>
      <td className={className}>{user.callname} / {user.company}</td>
      <td className={className + ' text-center'}><Consent mailing={user.allowPromoMailing} privacy={user.privacyNoticeAccepted}/></td>
      <Tier tier={user.promoMailGroup} onTierClick={onTierClick}/>
      <td className={className + ' text-center'}>{user.userStatus}</td>
      <td className={className + ' text-center'}>{user.role}</td>
    </tr>
  );
})

const TiersButton = observer(({tiersButton}) => {
  const {text, onClick} = tiersButton;
  if (onClick) {
    return (
      <Button className='mb-2 ml-1' color='primary' onClick={onClick}>{text}</Button>
    );
  }
  return (<span className='ml-1'>{text}</span>);
})

class AccountsList extends React.Component {
  componentWillUnmount() {
    clearTimeout(this.filterTimeout);
  }

  onFilterChange = ({target}) => {
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => this.props.store.setFilter(target.value), 200);
  }

  render() {
    const {store} = this.props;
    if (store.editUser) {
      return null;
    }

    const onCreateNewUser = () => store.setEditUser('');
    const onUserClick = (username) => () => store.setEditUser(username);
    const onTierClick = (username) => (delta) => store.changeUserTier(username, delta);

    const rows = store.users.map(user => (
      <TableRow
        user={user}
        key={user.username}
        myUsername={store.username}
        onClick={onUserClick(user.username)}
        onTierClick={store.tiers ? onTierClick(user.username) : null}/>
      )
    );

    return (
      <Container>
        <Row className='justify-content-center'>
          <Col>
            <h1 className='text-center'>Accounts</h1>
            <StatusMessage error={store.error} success={store.success}/>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col>
            <Button className='mb-2' color='primary' onClick={onCreateNewUser}>Create new user</Button>
            <TiersButton tiersButton={store.tiersButton}/>
          </Col>
          <Col>
            <Input type='text' placeholder={`Search... (use e.g. 't5' to filter tiers or try 'naughty')`} onChange={this.onFilterChange}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table hover striped>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Name / Company</th>
                  <th className='text-center'>Consent</th>
                  <th className='text-center'>Tier</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Role</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col>
            <Stats {...store.stats}/>
          </Col>
        </Row>
      </Container>
    );
  }
}

AccountsList.propTypes = {
  store: PropTypes.instanceOf(AccountsStore).isRequired
};

export default observer(AccountsList);
