import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Button} from 'reactstrap';
import ContactStore from '../../stores/ContactStore';
import {publicUrl} from '../../utils';

const ContactPerson = (props) => {
  const {topic, name, email, number} = props;
  return (
    <div className='mb-3'>
      <h4>{topic}</h4>
      <div>{name}</div>
      <div>{email}</div>
      <div>{number}</div>
    </div>
  );
}

const ContactPersons = ({store}) => {
  if (store.admin && store.editContacts.length > 0) {
    return null;
  }

  const content = store.contacts.map((contact, index) => (<ContactPerson key={index} {...contact}/>));
  const adminButton = store.admin ?
    (<Button onClick={() => { store.editMode = true; }}>EDIT CONTACTS</Button>) :
    null;

  return (
    <div>
      <div>
        <img className='img-fluid mb-5' src={publicUrl('img/logo.svg')} style={{maxWidth: '200px'}} alt='Relax Gaming logo'/>
      </div>
      {content}
      {adminButton}
    </div>
  );
}
ContactPersons.propTypes = {
  store: PropTypes.instanceOf(ContactStore).isRequired
}

export default observer(ContactPersons);
