/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, NavbarBrand, NavbarToggler, Collapse, Nav} from 'reactstrap';
import {publicUrl} from '../../utils';
import {STARTPAGE} from '../../config';
import {without} from 'ramda';

const MenuItem = (props) => {
  const {content, currentPage, navigateTo} = props;
  const name = content.toLowerCase();
  const active = currentPage.toLowerCase() === name;
  const className = without([null], ['nav-item nav-link', active ? 'active' : null]).join(' ');

  const onClick = (event) => {
    event.preventDefault();
    navigateTo(name);
  }

  return (
    <a className={className} onClick={onClick}>{content.toUpperCase()}</a>
  );
}

MenuItem.propTypes = {
  content: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  navigateTo: PropTypes.func.isRequired
}

class Navigation extends React.Component {
  static propTypes = {
    currentPage: PropTypes.string.isRequired,
    pages: PropTypes.arrayOf(PropTypes.string).isRequired,
    user: PropTypes.shape({
      company: PropTypes.string,
      callname: PropTypes.string,
      role: PropTypes.string
    }).isRequired,
    callback: PropTypes.func
  }

  constructor(props) {
    super();

    this.state = {
      collapsed: true
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const {currentPage, user, pages, callback} = this.props;

    const navigateTo = (page) => {
      callback('navigate', page);
      this.setState({collapsed: true});
    }

    const onClick = (pageid) => (event) => {
      event.preventDefault();
      navigateTo(pageid);
    }

    const navItems = pages.map((page) => <MenuItem key={page} content={page} currentPage={currentPage} navigateTo={navigateTo}/>);

    return (
      <Container fluid>
        <Row className='nav-wrapper'>
          <nav className='navbar navbar-expand-lg navbar-dark'>
            <NavbarBrand onClick={() => {navigateTo(STARTPAGE)}}><img src={publicUrl('img/logo-white.svg')} alt='Relax Gaming Logo'/></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar}/>
            <Collapse navbar isOpen={!this.state.collapsed}>
              <Nav navbar className='ml-auto align-items-center'>
                {navItems}
                <a className='nav-item nav-link small' onClick={onClick('account')}><span className='fas fa-user'/>{user.callname}</a>
                <a className='nav-item nav-link small' onClick={onClick('logout')}><span className='fas fa-sign-out-alt'/>Logout</a>
              </Nav>
            </Collapse>
          </nav>
        </Row>
      </Container>
    );
  }
}

export default Navigation;
