export const mailTemplate = `
  <center class="wrapper" data-link-color="#888888" data-body-style="font-size: 14px; font-family: arial,helvetica,sans-serif; color: #000000; background-color: #000000;">
  <div class="webkit">
    <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#000000">
      <tr>
        <td valign="top" bgcolor="#000000" width="100%">
          <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
            <tr>
              <td width="100%">
                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                  <tr>
                    <td>
                      <!--[if mso]>
                      <center>
                      <table><tr><td width="600">
                      <![endif]-->
                      <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width: 100%; max-width:600px;" align="center">
                        <tr>
                          <td role="modules-container" style="padding: 0px 0px 0px 0px; color: #000000; text-align: left;" bgcolor="#000000" width="100%" align="left">

<table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%"
       style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
  <tr>
    <td role="module-content">
      <p></p>
    </td>
  </tr>
</table>

<table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
  <tr>
    <td style="font-size:6px;line-height:10px;padding:18px 0px 18px 0px;background-color:#000000;" valign="top" align="center">
      <a href="https://relax-gaming.com"><img class="max-width" style="display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;" src="https://marketing-image-production.s3.amazonaws.com/uploads/35a4f40b8c1719b906ad188b69b4cad72dcc714927e6869d70107915141e14304b5b70857523808ce69d219c71996b1e80188d8758b6e680c2458a4ad21888ea.png" alt="Relax Gaming" title="Relax Gaming" width="200" height="63" border="0"></a>
    </td>
  </tr>
</table>

<table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
<tr>
  <td style="padding:20px 20px 20px 20px;text-align:inherit;background-color:#ffffff;"
      height="100%"
      valign="top"
      bgcolor="#ffffff">
      <div><p style="color:#333333;font-size: 25px;line-height: 20px;">{{subjectText}}<br><br></p></div>
      <div>
        <p style="color:#333333;">{{bodyText}}<br><br></p>
        <p style="color:#333333;">{{greeting}}</p>
        <p style="color:#333333;">{{signature}}</p>
    </div>
  </td>
</tr>
</table>

{{bodyImage}}

<table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
  <tr>
    <td style="background-color:#000000;padding:18px 0px 18px 0px;line-height:22px;text-align:inherit;"
        height="100%"
        valign="top"
        bgcolor="#000000">
    </td>
  </tr>
</table>

<table class="module" role="module" data-type="social" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
  <tbody>
    <tr>
      <td valign="top" style="padding:0px 0px 0px 0px;font-size:6px;line-height:10px;">
        <table align="center">
          <tbody>
            <tr>
              <td style="padding: 0px 5px;">
    <a role="social-icon-link"  href="https://www.facebook.com/RelaxGamingRG" target="_blank" alt="Facebook"
      data-nolink="false"
      title="Facebook "
      style="-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;display:inline-block;">
      <img role="social-icon" alt="Facebook" title="Facebook"
        height="32"
        width="32"
        style="height: 32px, width: 32px"
        src="https://marketing-image-production.s3.amazonaws.com/uploads/28b16d9a4d0b55d80bc411cc3d87e019d4319b4f33e7fee814bff8142d114ca99e5d7709d5f1bff5e287f4feb6a376c44b0de7389fc02ec95383703d8fd842b1.png" />
    </a>
  </td>




              <td style="padding: 0px 5px;">
    <a role="social-icon-link"  href="https://www.linkedin.com/company/relax-gaming-ltd" target="_blank" alt="LinkedIn"
      data-nolink="false"
      title="LinkedIn "
      style="-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;display:inline-block;">
      <img role="social-icon" alt="LinkedIn" title="LinkedIn"
        height="32"
        width="32"
        style="height: 32px, width: 32px"
        src="https://marketing-image-production.s3.amazonaws.com/uploads/249e91a2c0224f0fbcffee81fc7883e5fe1a26c89fc1716eaca1f476cad869bb9ee5f1962733270e42f586ed67d9ccadffb18e27d033a42303a233c3159013e6.png" />
    </a>
  </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

<table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
  <tr>
    <td style="padding:18px 0px 18px 0px;line-height:16px;text-align:inherit;"
        height="100%"
        valign="top"
        bgcolor="">
        <div style="text-align: center;"><span style="color: rgb(136, 136, 136); font-family: sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; text-align: center; background-color: rgb(0, 0, 0);">© 2019 Relax Gaming Group</span><br style="color: rgb(136, 136, 136); font-family: sans-serif; font-size: 12px; text-align: center; background-color: rgb(0, 0, 0); text-size-adjust: 100%;">
<span class="unstyle-auto-detected-links" style="color: rgb(136, 136, 136); font-family: sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; text-align: center; background-color: rgb(0, 0, 0); text-size-adjust: 100%;">Chains Business Centre, 99 George Borg Olivier Street, St.Julian’s STJ1080, Malta</span></div>

<div style="text-align: center;">&nbsp;</div>

<div style="text-align: center;"><span style="color: rgb(136, 136, 136); font-family: sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; text-align: center; background-color: rgb(0, 0, 0);">Unsubscribe at&nbsp;</span><a href="https://clientarea.relax-gaming.com" style="color: rgb(136, 136, 136); text-decoration-line: underline; background-color: rgb(0, 0, 0); cursor: pointer; font-family: sans-serif; font-size: 12px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; text-align: center; text-size-adjust: 100%;">https://clientarea.relax-gaming.com</a></div>
    </td>
  </tr>
</table>

                          </td>
                        </tr>
                      </table>
                      <!--[if mso]>
                      </td></tr></table>
                      </center>
                      <![endif]-->
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</center>
`;


export const imgTemplate = `
<table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
  <tr>
    <td style="font-size:6px;line-height:10px;padding:0px 0px 0px 0px;" valign="top" align="center">
      <img class="max-width" border="0" style="display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:100% !important;width:100%;height:auto !important;" width="600" src="{{bodyImage}}">
    </td>
  </tr>
</table>
`;
