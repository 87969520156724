import React from 'react';
import PropTypes from 'prop-types';
import {remove, type} from 'ramda';
import {FormGroup, Button, Input} from 'reactstrap';

class FilePicker extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired
  }

  constructor() {
    super();

    this.state = {
      files: []
    };

    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const {files} = this.state;
    if (prevState.files.length !== files.length) {
      this.props.callback('files', files);
    }
  }

  onChooseFile = (event) => {
    event.preventDefault();
    const file = this.ref.current.files[0];
    this.setState({files: this.state.files.concat([file])});
  }

  onRemoveFile = (index) => {
    return (event) => {
      event.preventDefault();
      this.setState({files: remove(index, 1, this.state.files)});
    }
  }

  render() {
    const addButton = this.state.files.length < 3
      ? (
        <FormGroup className='my-1'>
          <Input
            innerRef={this.ref}
            type='file'
            color='primary'
            accept='image/jpeg,image/png,image/gif'
            onChange={this.onChooseFile}/>
          </FormGroup>)
      : null;

    const files = this.state.files.map((file, index) => {
      const name = type(file) === 'String' ? file : file.name;
      return (
        <div key={index} className='my-1 d-flex justify-content-between align-items-center'>
          <span>{name}</span>
          <Button size='sm' className='ml-1' color='danger' onClick={this.onRemoveFile(index)}>remove</Button>
        </div>
      );
    });

    return (
      <div>
        {files}
        {addButton}
      </div>
    );
  }
}

export default FilePicker;
