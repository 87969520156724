import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'reactstrap';

const toState = ({error, success}) => {
  if (error) {
    return {
      message: error,
      color: 'danger'
    }
  }
  else if (success) {
    return {
      message: success,
      color: 'success'
    }
  }

  return {
    message: '',
    color: ''
  }
}

class StatusMessage extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    success: PropTypes.string
  }

  constructor(props) {
    super();

    this.state = toState(props);
  }

  componentDidUpdate(prevProps) {
    const state = toState(this.props);
    if (state.message !== this.state.message) {
      this.setState(state);
    }
  }

  render() {
    const {message, color} = this.state;
    if (message) {
      return <Alert className={this.props.className} color={color}>{message}</Alert>
    }
    return null;
  }
}

export default StatusMessage;
