import {extendObservable, decorate, action, computed} from 'mobx';
import Users from '../api/Users';
import {isValidEmail} from '../utils';
import {pipe, map, uniq, sortBy, propEq, toLower, values, join, remove, pick, head, drop} from 'ramda';

const defaultUser = {
  username: '',
  callname: '',
  email: '',
  role: 'user',
  company: '',
  allowPromoMailing: false,
  promoMailGroup: 0
}

export const pickUserFields = (user) => {
  return pick([
    'username',
    'callname',
    'email',
    'role',
    'company',
    'allowPromoMailing',
    'promoMailGroup'], {...defaultUser, ...user});
}

class AccountsStore {
  constructor(api, username, initialRequest = true) {
    extendObservable(this, {
      _users: [],
      filter: '',
      editUser: null,
      editInvalid: {},
      tiers: null,
      tierStatus: '',
      error: '',
      success: ''
    });

    this.api = api;
    this.username = username;

    if (initialRequest) {
      this.requestUserList();
    }
  }

  // API calls

  requestUserList = (callback) => {
    const onUserList = (payLoad) => {
      this._onUserList(payLoad);
      if (typeof callback === 'function') {
        callback();
      }
    };
    Users.list(this.api, onUserList, this._onError);
  }

  requestAddUser = () => {
    if (this.editUser) {
      Users.add(this.api, pickUserFields(this.editUser), this._onAddUser, this._onError);
    }
  }

  requestEditUser = () => {
    if (this.editUser) {
      Users.edit(this.api, pickUserFields(this.editUser), this._onEditUser, this._onError);
    }
  }

  requestDeleteUser = () => {
    if (this.editUser) {
      Users.delete(this.api, this.editUser.username, this._onDeleteUser, this._onError);
    }
  }

  requestResetUserPw = () => {
    if (this.editUser) {
      Users.resetpw(this.api, this.editUser.username, this._onResetUserPw, this._onError);
    }
  }

  // actions

  _onUserList = (payLoad) => {
    this._users = payLoad || [];
  };

  _onAddUser = (payLoad) => {
    this._updateUser(payLoad);
    this.setEditUser(null);
    this.setStatus('', `User '${payLoad.username}' added`);
  }

  _onEditUser = (payLoad) => {
    this._updateUser(payLoad);
    this.setEditUser(null);
    this.setStatus('', `User '${payLoad.username}' edited`);
  }

  _onDeleteUser = ({username}) => {
    const index = this._findUserIndex(username);
    if (index > -1) {
      this._users = remove(index, 1, this._users);
    }
    this.setEditUser(null);
    this.setStatus('', `User '${username}' deleted`);
  };

  _onResetUserPw = (payLoad) => {
    this._updateUser(payLoad);
    this.setEditUser(null);
    this.setStatus('', `Password reset for user '${payLoad.username}'`)
  }

  _updateUser = (user, editOnly) => {
    if (!user) {
      return;
    }

    const index = this._findUserIndex(user.username);
    if (index > -1) {
      this._users[index] = user;
    }
    else if (!editOnly) {
      this._users.push(user);
    }
  }

  _onError = (error) => {
    this.setStatus(error.msg);
  }

  setStatus = (error, success) => {
    this.error = error || '';
    this.success = success || '';
  }

  setEditUser = (username) => {
    if (username === '') {
      this.editUser = defaultUser;
      this.editUser.new = true;

    }
    else if (!username) {
      this.editUser = null;
    }
    else {
      const user = this.findUserByUsername(username);
      this.editUser = !!user ? {...this.editUser, ...pickUserFields(user)} : null;
    }
    this.setStatus();
  }

  editTiers = () => {
    this.setStatus();
    this.tiers = new Map();
  }

  changeUserTier = (username, delta) => {
    const d = Math.sign(delta);
    if (!d) {
      return;
    }

    const user = this.findUserByUsername(username);
    if (user) {
      // tier 0 and -1 are special cases, so cannot use: tiers.get(username) || user.promoMailGroup
      const currentTier = Math.max(this.tiers.has(username) ? this.tiers.get(username) : user.promoMailGroup, 0);
      const newTier = Math.max(currentTier + d, 0);
      if (newTier === user.promoMailGroup) {
        this.tiers.delete(username);
      }
      else {
        this.tiers.set(username, newTier);
      }
    }
  }

  saveTiers = (tiers) => {
    if (Array.isArray(tiers)) {
      if (tiers.length > 0) {
        const [username, tier] = head(tiers);
        const user = this.findUserByUsername(username);
        const rest = drop(1, tiers);

        const onTierSet = action((payLoad) => {
          this._updateUser(payLoad);
          if (rest.length > 0) {
            this.tierStatus = `Updating tiers... ${this.tiers.size - rest.length} / ${this.tiers.size}`
            this.saveTiers(rest);
          }
          else {
            this.tierStatus = '';
            const s = this.tiers.size > 1 ? 's' : '';
            const u = !!s ? `${this.tiers.size} users` : `'${payLoad.username}'`
            this.setStatus('', `Tier${s} updated for ${u}`);
            this.tiers.clear();
            this.tiers = null;
          }
        })

        const onTierError = action((error) => {
          this.tierStatus = '';
          this.setStatus(error.msg, '');
        })

        Users.edit(this.api, {...user, ...{promoMailGroup: tier}}, onTierSet, onTierError);
      }
      else {
        this.tiers.clear();
        this.tiers = null;
        this.setStatus('', 'All OK, no tiers changed');
      }
    }
    else {
      this.setStatus();
      this.saveTiers([...this.tiers.entries()]);
    }
  }

  setFilter = (filter) => {
    this.filter = filter;
  }

  // util

  _findUserIndex = (username) => this._users.findIndex(propEq('username', username));
  findUserByUsername = (username) => this._users[this._findUserIndex(username)];

  // computed

  get users() {
    // if tiers are being edited, we need to show the tier from tiers map instead of the user data
    const applyTierEdits = (tiers) => (users) => !tiers ? users : users.map(user => ({
      ...user,
      // tier 0 and -1 are special cases, so cannot use: tiers.get(username) || user.promoMailGroup
      ...{promoMailGroup: tiers.has(user.username) ? tiers.get(user.username) : user.promoMailGroup}
    }));

    const applyTextFilter = (filter) => (users) => {
      if (filter) {
        const f = filter.trim().toLowerCase();

        if (f === 'naughty') {
          return users.filter((user) => !(user.allowPromoMailing && user.privacyNoticeAccepted));
        }

        if (/^[t][\s]{0,}[1-9]{1,}$/g.test(f)) {
          const tier = parseInt((f.replace(/[^\d]/g, '')));
          return users.filter(user => user.promoMailGroup === tier);
        }

        const stringifyUser = pipe(values, join(' '), toLower);
        return users.filter(user => stringifyUser(user).indexOf(f) > -1);
      }
      return users;
    }

    return pipe(
      applyTierEdits(this.tiers),
      applyTextFilter(this.filter))(this._users);
  }

  get companies() {
    const getCompanies = pipe(map(user => user.company), uniq, sortBy(toLower));
    return getCompanies(this._users);
  }

  get usernames() {
    return this.users.map(user => user.username);
  }

  get editUsernameInvalid() {
    if (this.editUser) {
      const {username} = this.editUser;
      if (!isValidEmail(username)) {
        return 'Invalid username (must be an e-mail)';
      }
      else if (this.editUser.new && this.findUserByUsername(username)) {
        return 'Username already exists';
      }
    }
    return '';
  }

  get editEmailInvalid() {
    if (this.editUser) {
      if (!isValidEmail(this.editUser.email)) {
        return 'Invalid e-mail';
      }
    }
    return '';
  }

  get editUserValid() {
    if (this.editUser) {
      const {company, callname, role} = this.editUser;
      return !!(company && callname && role && !this.editEmailInvalid && !this.editUsernameInvalid);
    }
    return false;
  }

  get tiersButton() {
    if (this.tierStatus) {
      return {text: this.tierStatus};
    }

    return !!this.tiers ?
      {text: 'Save tiers', onClick: this.saveTiers} :
      {text: 'Edit tiers', onClick: this.editTiers};
  }

  get stats() {
    const total = this.users.length;
    const privacy = this.users.filter(propEq('privacyNoticeAccepted', true)).length;
    const mailing = this.users.filter(propEq('allowPromoMailing', true)).length;
    return {
      total,
      privacy,
      mailing,
      privacyp: Math.round(privacy / total * 100) || 0,
      mailingp: Math.round(mailing / total * 100) || 0
    }
  }
}

decorate(AccountsStore, {
  _onUserList: action,
  _onAddUser: action,
  _onDeleteUser: action,
  _onResetUserPw: action,
  _updateUser: action,
  _onError: action,
  setStatus: action,
  setEditUser: action,
  editTiers: action,
  changeUserTier: action,
  saveTiers: action,
  setFilter: action,

  users: computed,
  companies: computed,
  usernames: computed,
  editUsernameInvalid: computed,
  editEmailInvalid: computed,
  editUserValid: computed,
  tiersButton: computed,
  stats: computed
});

export default AccountsStore;
