import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {reaction} from 'mobx';
import {Input} from 'reactstrap';
import moment from 'moment';
import DateTimeStore from '../../../stores/DateTimeStore';

class DateTimePicker extends React.Component {
  static propTypes = {
    initial: PropTypes.number,
    onChange: PropTypes.func
  }

  constructor({initial}) {
    super();
    this.store = new DateTimeStore(initial, () => moment().unix());
  }

  componentDidMount() {
    this.setDefaults(this.props.initial);
    this.disposeTimestamp = reaction(() => this.store.timestamp, this.props.onChange);
  }

  componentWillUnmount() {
    this.disposeTimestamp();
  }

  onDateChange = ({target}) => {
    this.store.setDate(target.value);
  }

  onTimeChange = ({target}) => {
    this.store.setTime(target.value);
  }

  setDefaults = (timestamp) => {
    if (timestamp) {
      const m = moment(timestamp, 'X');
      this.defaults = {
        date: [m.date(), m.month() + 1, m.year()],
        time: [m.hour(), m.minute()]
      };
    }
    else {
      const m = moment();
      this.defaults = {
        date: [m.date(), m.month() + 1, m.year()],
        time: null
      };
    }
  }

  render() {
    return (
      <div className='datetime-picker'>
        <span>Date & time (optional):</span>
        <Input
          className='date'
          type='text'
          maxLength={10}
          placeholder='DD.MM.YYYY'
          value={this.store.date}
          onChange={this.onDateChange}
          onBlur={this.store.fillDate}/>
        {this.store.timeEditable ?
          <Input
            className='time'
            type='text'
            maxLength={5}
            placeholder='HH:MM'
            value={this.store.time}
            onChange={this.onTimeChange}
            onBlur={this.store.fillTime}/> : null}
        <span className='reset'>
          <span className='fas fa-times-circle' style={{cursor: 'pointer'}} onClick={this.store.reset}/>
        </span>
        <div className='warning'>{this.store.invalid}</div>
      </div>
    );
  }
}

export default observer(DateTimePicker);
