import React from 'react';
import PropTypes from 'prop-types';

const ProgressSpinner = ({disabled, white}) => {
  if (disabled) {
    return null;
  }

  return (
    <span className='progress-spinner'>
      <span className={`spinner${white ? ' white' : ''}`}/>
    </span>
  );
}
ProgressSpinner.propTypes = {
  disabled: PropTypes.bool,
  white: PropTypes.bool
}
ProgressSpinner.defaultProps = {
  disabled: false,
  white: false
}

export default ProgressSpinner;
