import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {FormGroup, Input, Button} from 'reactstrap';
import ContactStore from '../../stores/ContactStore';
import {publicUrl} from '../../utils';

const PersonInput = observer((props) => {
  const id = `${props.name}${props.index}`;
  return (
    <Input className='my-1' placeholder={props.title} type='text' id={id} {...props}/>
  );
})

const EditContactPerson = observer(({topic, name, email, number, index, onChange, onDelete}) => {
  const _onChange = ({target}) => {
    const {name, value} = target;
    onChange(name, value);
  }

  return (
    <FormGroup>
      <PersonInput title='Topic' index={index} name='topic' value={topic} onChange={_onChange}/>
      <PersonInput title='Name' index={index} name='name' value={name} onChange={_onChange}/>
      <PersonInput title='E-mail' index={index} name='email' value={email} onChange={_onChange}/>
      <PersonInput title='Phone' index={index} name='number' value={number} onChange={_onChange}/>
      <br/>
      <Button color='danger' size='sm' onClick={onDelete}>Delete</Button>
    </FormGroup>
  );
});

const EditContactPersons = ({store}) => {
  const onChange = (index) => (key, value) => store.updateContact(index, key, value);
  const onDelete = (index) => () => store.deleteContact(index);
  const onSave = () => store.requestSetContacts();
  const onAdd = () => store.addContact();
  const onCancel = () => { store.editMode = false; }

  if (!(store.admin && store.editContacts.length > 0)) {
    return null;
  }

  const content = store.editContacts.map((contact, index) => (
    <EditContactPerson
      key={index}
      index={index}
      onChange={onChange(index)}
      onDelete={onDelete(index)}
      {...contact}/>
  ));

  return (
    <div className='editcontacts'>
      <div>
        <img className='img-fluid mb-5' src={publicUrl('img/logo.svg')} style={{maxWidth: '200px'}} alt='Relax Gaming logo'/>
      </div>
      {content}
      <Button className='mx-1 mt-1' color='primary' onClick={onSave}>Save</Button>
      <Button className='mx-1 mt-1' color='secondary' onClick={onAdd}>Add new</Button>
      <Button className='mx-1 mt-1' color='secondary' onClick={onCancel}>Cancel</Button>
    </div>
  );
}
EditContactPersons.propTypes = {
  store: PropTypes.instanceOf(ContactStore).isRequired
}

export default observer(EditContactPersons);
