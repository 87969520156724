import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import {Container, Row, Col, Input} from 'reactstrap';
import GamesStore from '../../stores/GamesStore';

class Roadmap extends React.Component {
  static propTypes = {
    store: PropTypes.instanceOf(GamesStore).isRequired
  }

  constructor() {
    super();

    this.state = {
      uploaded: null
    }

    this.ref = React.createRef();
  }

  onChooseFile = (event) => {
    event.preventDefault();
    const file = this.ref.current.files[0];
    if (file) {
      this.props.store.addRoadmap(file, this.onProgress);
    }
  }

  onProgress = ({direction, percent}) => {
    if (direction === 'upload') {
      this.setState({uploaded: percent});
    }
    else {
      this.setState({uploaded: null});
    }
  }

  render() {
    const UploadButton = () => {
      if (!this.props.store.admin) {
        return null;
      }

      if (this.state.uploaded) {
        return (
          <div>
            <label className='btn btn-primary'>
              <span>Uploading... {parseInt(this.state.uploaded, 10)}%</span>
            </label>
          </div>
        );
      }

      return (
        <div>
          <label className='btn btn-primary upload mt-2'>
            <Input style={{display: 'none'}}
              accept='application/pdf,application/zip'
              innerRef={this.ref}
              type='file'
              color='primary'
              onChange={this.onChooseFile}/>
            <span>Upload New</span>
          </label>
        </div>
      );
    }

    const url = this.props.store.roadmap.path;

    const onDownload = () => {
      ReactGA.event({
        category: 'Roadmap',
        action: 'Download'
      });
    };

    return (
      <div className='broschure'>
        <Container>
          <Row className='pt-5 pb-5'>
            <Col className='col-md-6 offset-md-3 text-center'>
              <h2>Roadmap</h2>
              <p>See all upcoming content</p>
              {!!url && <a onClick={onDownload} className='btn btn-lg' download href={url}>View Roadmap</a>}
              {!url && <span>Roadmap missing!</span>}
              <UploadButton/>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default observer(Roadmap);
