import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import {omit} from 'ramda';

export const FormTextInput = (props) => {
  const {label, validate, value, feedback} = props;
  const rest = omit(['label', 'validate', 'feedback'], props);
  const invalid = validate ? !validate(value) : false;
  const formfeedback = feedback ? (<FormFeedback>{feedback}</FormFeedback>) : null;
  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input
        type='text'
        invalid={invalid}
        {...rest}/>
      {formfeedback}
    </FormGroup>
  );
}
FormTextInput.propTypes = {
  label: PropTypes.string,
  validate: PropTypes.func,
  value: PropTypes.string,
  feedback: PropTypes.string
}

export const FormSelect = (props) => {
  const {label, options} = props;
  const rest = omit(['label', 'options'], props);
  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input type='select' {...rest}>
        {options}
      </Input>
    </FormGroup>
  );
}
FormSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
}
