/* eslint-disable jsx-a11y/alt-text,jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {Col} from 'reactstrap';
import {publicUrl, buildShortDescription} from '../../utils';

const FeaturedItem = (props) => {
  const onClick = () => {
    props.selectGame(props.game.gameid);
    ReactGA.event({
      category: 'Open Game',
      action: 'Featured'
    });
  }

  const {mobile, game} = props;
  const tag = !mobile ? (<div className='tag'>featured</div>) : null;
  const button = mobile ? (<a className='btn btn-lg mt-4'>See More</a>) : null;

  const banner = publicUrl(game.bannerimageSmall || 'img/810x382.jpg');
  const description = buildShortDescription(game, true);

  return (
    <Col md={6} className={mobile ? 'px-0' : ''}>
      <div className='featured-item' onClick={onClick}>
        {tag}
        <img src={banner} alt='Game banner'/>
        <h2>{game.gameName}</h2>
        <img src={publicUrl('img/featured-dotline.svg')} className='dotline'/>
        <p>{description}</p>
        {button}
      </div>
    </Col>
  );
}

FeaturedItem.propTypes = {
  mobile: PropTypes.bool,
  game: PropTypes.object
}

export default FeaturedItem;
