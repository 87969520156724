import React from 'react';
import PropTypes from 'prop-types';
import {action, extendObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Container, Row, Col, Form, Button, FormGroup, Label, Input} from 'reactstrap';
import AccountsStore from '../../stores/AccountsStore';
import {FormTextInput, FormSelect} from '../../components/Form';
import {UserStatuses, UserRoles} from '../../config';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import StatusMessage from '../../components/StatusMessage';
import {toPairs, isNil, propOr} from 'ramda';

const NewUserControls = observer(({store, onClick}) => {
  return (
    <Row>
      <Col className='d-flex justify-content-center'>
        <Button className='m-1' name='create' disabled={!store.editUserValid} color='primary' onClick={onClick}>Create</Button>
      </Col>
      <Col className='d-flex justify-content-center'>
        <Button className='m-1' name='cancel' color='secondary' onClick={onClick}>Cancel</Button>
      </Col>
    </Row>
  );
})

const EditUserControls = observer(({store, onClick}) => {
  return (
    <Row>
      <Col lg={3} className='d-flex justify-content-center'>
        <Button className='m-1' name='save' disabled={!store.editUserValid} color='primary' onClick={onClick}>Save changes</Button>
      </Col>
      <Col lg={3} className='d-flex justify-content-center'>
        <Button className='m-1' name='resetpw' color='warning' onClick={onClick}>Reset password</Button>
      </Col>
      <Col lg={3} className='d-flex justify-content-center'>
        <Button className='m-1' name='delete' color='danger' onClick={onClick}>Delete user</Button>
      </Col>
      <Col lg={3} className='d-flex justify-content-center'>
        <Button className='m-1' name='cancel' color='secondary' onClick={onClick}>Cancel</Button>
      </Col>
    </Row>
  );
})

const CompanyInput = observer(
class CompanyInput extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super();

    extendObservable(this, {
      options: []
    });

    this.resetOptions(props.options);
  }

  resetOptions = action((options) => {
    this.options = options;
  })

  onInputChange = ({target}) => {
    this.props.onChange(target.value);
  }

  onSelectChange = action((event) => {
    const {value} = event.target;
    if (value === '__add') {
      this.options = null;
      this.props.onChange('');
    }
    else {
      this.props.onChange(value);
    }
  })

  onKeyDown = (event) => {
    if (event.key === 'Backspace' && this.props.value === '') {
      this.resetOptions(this.props.options);
    }
  }

  render() {
    if (!this.options) {
      return (
        <FormTextInput
          name='company'
          label='Company'
          autoFocus
          value={this.props.value}
          onKeyDown={this.onKeyDown}
          onChange={this.onInputChange}/>
      );
    }

    return (
      <FormSelect
        name='company'
        label='Company'
        value={this.props.value}
        options={this.options}
        onChange={this.onSelectChange}/>
    );
  }
}
)

const TierInput = observer(({name, value, onChange}) => {
  return (
    <FormGroup>
      <Label>Tier (0 to disable)</Label>
      <Input
        name={name}
        value={value}
        onChange={onChange}
        type='text'
        min='0'/>
    </FormGroup>
  );
})

const createCompanyOptions = (companies) => {
  const empty = (<option key='' disabled value=''>Choose...</option>);
  const addNew = (<option key='__add' value='__add'>Add new...</option>);
  const options = companies.map((company) => (<option key={company} value={company}>{company}</option>));
  return [empty, addNew].concat(options);
}

const createOptions = (data) => {
  return toPairs(data).map(([key, value]) => (<option key={key} value={key}>{value}</option>));
}

class EditUser extends React.Component {
  static propTypes = {
    store: PropTypes.instanceOf(AccountsStore).isRequired
  }

  constructor(props) {
    super();

    if (!props.store.editUser) {
      return;
    }

    extendObservable(this, {
      dialog: null
    });

    this.companyOptions = createCompanyOptions(props.store.companies);
    this.roleOptions = createOptions(UserRoles);
    this.statusOptions = createOptions(UserStatuses);
  }

  onFormChange = action(({target}) => {
    this.props.store.editUser[target.name] = target.value;
  })

  onTierChange = action(({target}) => {
    const val = parseInt(target.value.replace(/[^\d]/g, '')) || 0;
    this.props.store.editUser.promoMailGroup = val;
  })

  onCompanyChange = action((value) => {
    this.props.store.editUser.company = value;
  })

  onButtonClick = (event) => {
    event.preventDefault();
    const {store} = this.props;
    const {username} = store.editUser
    switch (event.target.name) {
      case 'save':
        store.requestEditUser();
        break;
      case 'create':
        store.requestAddUser();
        break;
      case 'resetpw':
        this.showDialog(
          'Reset password',
          `Are you sure to reset password for user '${username}'?`,
          () => store.requestResetUserPw());
        break;
      case 'delete':
        this.showDialog(
          'Delete',
          `Are you sure to delete user '${username}'?`,
          () => store.requestDeleteUser());
        break;
      case 'cancel':
        store.setEditUser(null);
        break;
      default:
    }
  }

  showDialog = action((title, msg, onOk) => {
    this.onDialogOk = action(() => {
      this.dialog = null;
      onOk();
    });
    this.dialog = {title, msg};
  })

  onDialogCancel = action(() => {
    this.onDialogOk = () => {};
    this.dialog = null;
  })

  render() {
    const {editUser, editUsernameInvalid, editEmailInvalid, error, success} = this.props.store;
    if (isNil(editUser)) {
      return null;
    }

    const Controls = !!editUser.new ? NewUserControls : EditUserControls;
    const {username, company, role, callname, email,} = editUser;
    const promoMailGroup = Math.max(editUser.promoMailGroup || 0, 0);

    return (
      <Container>
        <Row className='justify-content-center'>
          <Col md='8' className='edituser'>
            <h1 className='text-center'>{editUser.new ? 'New ' : 'Edit '} user</h1>
            <StatusMessage error={error} success={success}/>
            <Form>
              <FormTextInput
                disabled={!editUser.new}
                name='username'
                label='Username (e-mail)'
                value={username}
                autoFocus
                required
                invalid={!!editUsernameInvalid}
                feedback={editUsernameInvalid}
                onChange={this.onFormChange}/>
              <CompanyInput
                value={company}
                required
                options={this.companyOptions}
                onChange={this.onCompanyChange}/>
              <FormSelect
                name='role'
                label='Role'
                required
                value={role}
                options={this.roleOptions}
                onChange={this.onFormChange}/>
              <TierInput
                value={promoMailGroup}
                onChange={this.onTierChange}/>
              <FormTextInput
                name='callname'
                label='Full name'
                required
                value={callname}
                onChange={this.onFormChange}/>
              <FormTextInput
                name='email'
                label='E-mail'
                required
                value={email}
                onChange={this.onFormChange}
                invalid={!!editEmailInvalid}
                feedback={editEmailInvalid}/>
            </Form>
            <Controls store={this.props.store} onClick={this.onButtonClick}/>
          </Col>
        </Row>
        <ConfirmationDialog
          title={propOr('', 'title', this.dialog)}
          msg={propOr('', 'msg', this.dialog)}
          isOpen={!!this.dialog}
          onOk={this.onDialogOk}
          onCancel={this.onDialogCancel}/>
      </Container>
    );
  }
}

export default observer(EditUser);
