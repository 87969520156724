/* eslint-disable jsx-a11y/alt-text,jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import {Container, Row, Col} from 'reactstrap';
import ImageCarousel from '../../../components/ImageCarousel';
import {SingleWrapper} from '../../ContentWrapper';
import GamesStore from '../../../stores/GamesStore';
import GameAssetEditor from './GameAssetEditor';
import {publicUrl, demoGameUrl, youTubeUrl} from '../../../utils';
import {GameDemo, YouTubePlayer} from './IFrameContainer';
import moment from 'moment';

const InfoItem = (props) => {
  if (props.children) {
    return (
      <div className='info-item'>
        <strong>{props.title}</strong><br/>
        {props.children}
      </div>
    );
  }
  return null;
}
InfoItem.propTypes = {
  title: PropTypes.string.isRequired
}

const DemoButton = ({game, onClick}) => {
  const text = 'Play demo';
  if (game && game.testBuildEnabled) {
    const PlayMobile = ({game}) => {
      if ((game.channels || []).indexOf('mobile') > -1) {
        return (
          <a
            className='btn btn-lg m-1 d-md-none'
            href={demoGameUrl(game.gameid, 'mobile')}
            target='_blank'
            rel="noopener noreferrer">{text}</a>
        );
      }

      return null;
    };

    return (
      <span>
        <a className='btn btn-lg m-1 d-none d-md-inline-block' onClick={onClick}>{text}</a>
        <PlayMobile game={game}/>
      </span>
    );
  }

  return null;
}
DemoButton.propTypes = {
  game: PropTypes.object,
  onClick: PropTypes.func.isRequired
}

const VideoButton = ({game, onClick}) => {
  if (game && game.videoURL) {
    const text = 'Show trailer';
    return (
      <span>
        <a className='btn btn-lg m-1 d-none d-md-inline-block' onClick={onClick}>{text}</a>
        <a className='btn btn-lg m-1 d-md-none'
          href={youTubeUrl(game.videoURL)}
          target='_blank'
          rel="noopener noreferrer">{text}</a>
      </span>
    );
  }

  return null;
}
VideoButton.propTypes = {
  game: PropTypes.object,
  onClick: PropTypes.func.isRequired
}

const DownloadButton = ({text, url}) => {
  const onClick = () => {
    ReactGA.event({
      category: 'Games',
      action: `Download ${text}`
    });
  }

  if (url) {
    return (
      <a onClick={onClick} className='btn btn-lg m-1' download href={publicUrl(url)}>{text}</a>
    );
  }

  return null;
}
DownloadButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string
}

const GameOverview = ({game}) => {
  const titles = [
    ['Game ID',            '',             'gameid'],
    ['Game type',          '',             'gameType'],
    ['RTP',                '',             'rtp'],
    ['Format',             '',             'rngType'],
    ['Payout Mechanic',    '',             'payoutMechanic'],
    ['Volatility',         '',             'volatility'],
    ['Max win per line',   '',             'maxWinPerLine'],
    ['Max coinciding win', '',             'maxCoincidingWin'],
    ['Max win',            '(sim)',        'maxWin'],
    ['Hit frequency',      '',             'hitFrequency'],
    ['Avg win',            '(free-spins)', 'avgWin'],
    ['Min bet',            '',             'minBet'],
    ['Max bet',            '',             'maxBet'],
  ];

  const content = titles
    .map(([title, specifier, key]) => ({title, specifier, value: game[key]}))
    .filter(({value}) => !!value)
    .map(({title, specifier, value}) => (
      <Col xs={12} sm={6} xl={4} className='p-1' key={title}>
        <div className='game-overview-item d-flex flex-column justify-content-between px-2 py-1'>
          <span>
            <span className='title'>{title}</span>
            {specifier ? <span className='specifier ml-1'>{specifier}</span> : null}
          </span>
          <span>{value}</span>
        </div>
      </Col>
    ));

  return (
    <div>
      <Row>
        {content}
      </Row>
    </div>
  );
}

const ReleaseDate = ({timestamp}) => {
  const m = moment(timestamp, 'X');
  if (m.isValid()) {
    const dateStr = m.format('DD.MM.YYYY')
    return (
      <React.Fragment>
        <br/><span><b>Release</b>&nbsp;{dateStr}</span>
      </React.Fragment>
    )
  }
  return null;
}

const GameDetails = ({game, store, selectGame, onPlayDemo, onPlayVideo}) => {
  if (!game) {
    return null;
  }

  const thumbnail2x = publicUrl(game.thumbnail2x || 'img/600x600.png');

  const descriptionParagraphs = (game.description || 'Description missing!')
    .split('\n')
    .filter(line => !!line)
    .map((line, index) => (<p key={index}>{line}</p>));

  return (
    <SingleWrapper>
      <Container className='game-details'>
        <a className='single-close game' onClick={() => selectGame(null)}>×</a>
        <Row>
          <Col md={4}>
            <div className='game-info'>
              <img src={thumbnail2x} className='img-fluid'/>
              <InfoItem title='Relax Contact Information'>
                {game.relaxContact}
              </InfoItem>
              <InfoItem title='Studio Contact Information'>
                {game.studioContact}
              </InfoItem>
            </div>
          </Col>
          <Col md={8}>
            <h1>{game.gameName}</h1>
            <p>
              <b>Developer</b>&nbsp;{game.developer}
              <ReleaseDate timestamp={game.releaseDate}/>
            </p>
            {descriptionParagraphs}
            <GameOverview game={game}/>
            <div className='game-buttons'>
              <DemoButton game={game} onClick={onPlayDemo}/>
              <DownloadButton text='Game sheet' url={game.gamesheet}/>
              <DownloadButton text='Game rules' url={game.gamerules}/>
              <DownloadButton text='Help file' url={game.helpfile}/>
              <DownloadButton text='Asset pack' url={game.marketingMaterial}/>
              <DownloadButton text='Math doc' url={game.mathDoc}/>
              <DownloadButton text='Game certificate' url={game.gamecertificate}/>
              <VideoButton game={game} onClick={onPlayVideo}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ImageCarousel urls={game.screenshots} alt='Screenshot'/>
          </Col>
        </Row>
        <GameAssetEditor key={game.gameid} game={game} store={store}/>
      </Container>
    </SingleWrapper>
  );
}
GameDetails.propTypes = {
  game: PropTypes.object,
  selectGame: PropTypes.func.isRequired,
  onPlayDemo: PropTypes.func.isRequired,
  onPlayVideo: PropTypes.func.isRequired
}

class GameDetailsContainer extends React.Component {
  static propTypes = {
    game: PropTypes.object,
    mode: PropTypes.string,
    store: PropTypes.instanceOf(GamesStore).isRequired,
    selectGame: PropTypes.func.isRequired
  }

  constructor() {
    super();

    this.state = {
      mode: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {mode} = this.props;
    if (prevProps.mode !== mode) {
      if (this.state.mode !== mode) {
        this.setState({mode: mode || ''});
      }
    }
  }

  render() {
    const {game, selectGame} = this.props;
    if (!game) {
      return null;
    }

    const onClose = () => selectGame(game.gameid);

    const selectWithGA = (action) => () => {
      selectGame(`${game.gameid}/${action}`);
      ReactGA.event({
        category: 'Games',
        action: `Play ${action}`
      });
    }

    if (this.state.mode === 'video') {
      return (<YouTubePlayer game={game} onClose={onClose}/>);
    }
    else if (this.state.mode === 'demo') {
      return (<GameDemo game={game} onClose={onClose}/>);
    }

    return (
      <GameDetails
        {...this.props}
        onPlayDemo={selectWithGA('demo')}
        onPlayVideo={selectWithGA('video')}/>
    );
  }
}

export default observer(GameDetailsContainer);
