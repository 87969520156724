import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Login from './Login';
import {changeUserPw, forgotpw} from '../../api/Users';
import {login, accorequest} from '../../api/Login';
import RequestHandler from '../../api/RequestHandler';

class LoginContainer extends React.Component {
  static propTypes = {
    api: PropTypes.object,
    user: PropTypes.object,
    clearAppError: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      mode: '',
      success: '',
      error: ''
    };

    this.reqs = new RequestHandler(this.setState.bind(this));
  }

  componentDidMount() {
    const rootEl = document.getElementById('root');
    this.rootClassName = rootEl.className;
    rootEl.className = 'h-100';

    if (this.props.api) {
      const {user} = this.props;
      if (user && user.username && user.userStatus) {
        this.onLogin(user)
      }
    }
  }

  componentWillUnmount() {
    this.reqs.unmount();
    document.getElementById('root').className = this.rootClassName;
  }

  requestLogin = ({username, password}) => {
    this.setMode();
    const req = login(this.props.api, username, password, this.onLogin, this.onError);
    this.reqs.add('login', req);
  }

  onLogin = (payLoad) => {
    this.props.clearAppError();
    const {username, userStatus} = payLoad;
    switch (userStatus) {
      case 'normal':
        return true; // pass handling to App.okFallback
      case 'changepw':
        this.setState({error: '', success: 'Please choose a new password.', username, password: '', mode: 'changepw'});
        return true;
      case 'blocked':
        this.setState({error: `User '${username}' blocked`, success: '', username: '', password: ''});
        break;
      case 'tokenExpired':
        this.setState({error: 'The login link is expired, please check your email for a new one.', success: '', username: '', password: ''});
        break;
      default:
        this.setState({error: 'Invalid user status. Please contact support.', success: '', username: '', password: ''});
    }
  }

  requestChangePw = ({password}) => {
    this.setMode();
    const onChangePw = ({message}) => this.setMode('', message);
    const req = changeUserPw(this.props.api, password, onChangePw, this.onError);
    this.reqs.add('changepw', req);
  }

  requestAccount = ({name, email, company}) => {
    this.setMode();
    const onAccoRequest = ({message}) => this.setMode('', message);
    const req = accorequest(this.props.api, name, email, company, onAccoRequest, this.onError);
    this.reqs.add('accorequest', req);
  }

  requestForgotPw = ({username}) => {
    this.setMode();
    const onForgotPw = ({message}) => this.setMode('', message);
    const req = forgotpw(this.props.api, username, onForgotPw, this.onError);
    this.reqs.add('forgotpw', req);
  }

  onError = ({msg}) => {
    this.setMode(this.state.mode, '', msg);
  }

  onCallback = (action, params) => {
    switch(action) {
      case 'login':
        this.requestLogin(params);
        break;
      case 'changepw':
        this.requestChangePw(params);
        break;
      case 'requestaccount':
        if (!params) {
          this.setMode('requestaccount');
        }
        else {
          this.requestAccount(params);
        }
        break;
      case 'forgotpw':
        if (!params) {
          this.setMode('forgotpw');
        }
        else {
          this.requestForgotPw(params);
        }
        break;
      default:
        this.setMode('');
    }
  }

  setMode = (mode, success, error) => {
    this.props.clearAppError();
    this.setState({
      mode: mode === undefined ? this.state.mode : mode || '',
      success: success || '',
      error: error
    });
  }

  render() {
    // NOTE: state.requesting is not used at the moment, even though it's somewhat handled in child components
    const {username, mode, success, requesting} = this.state;
    const error = this.props.error || this.state.error;
    return (
      <Login
        username={username}
        mode={mode}
        success={success}
        error={error}
        requesting={!!requesting}
        callback={this.onCallback}/>
    );
  }
}

export default observer(LoginContainer);
