import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Col, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import ContactStore from '../../stores/ContactStore';

const ContactForm = ({store}) => {
  const onChange = ({target}) =>  store.setMessage(target.value);
  const onSubmit = (event) => {
    event.preventDefault();
    store.requestSubmit();
  }

  return (
    <Col sm='8' className='contact'>
      <h2>Contact</h2>
      <p></p>
      <Form className='mt-5' onSubmit={onSubmit}>
        <FormGroup>
          <Label>Message</Label>
          <Input
            type='textarea'
            name='message'
            value={store.message}
            onChange={onChange}/>
        </FormGroup>
        <FormGroup>
          <Button
            size='lg'
            className='mt-2'
            disabled={store.message.length < 1}
            type='submit'>SEND MESSAGE</Button>
        </FormGroup>
      </Form>
    </Col>
  );
}
ContactForm.propTypes = {
  store: PropTypes.instanceOf(ContactStore).isRequired
}

export default observer(ContactForm);
