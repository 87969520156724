import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ToolsStore from '../../stores/ToolsStore';
import ContentWrapper from '../ContentWrapper';
import Mailing from './Mailing';

class Tools extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    tiers: PropTypes.arrayOf(PropTypes.number)
  }

  constructor(props) {
    super();
    this.store = new ToolsStore(props.api);
  }

  render() {
    return (
      <ContentWrapper>
        <Mailing api={this.props.api} store={this.store}/>
      </ContentWrapper>
    );
  }
}

export default observer(Tools);
