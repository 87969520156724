import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import AccountsStore from '../../stores/AccountsStore';
import ContentWrapper from '../ContentWrapper';
import AccountsList from './AccountsList';
import EditUser from './EditUser';

class Accounts extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired
  }

  constructor(props) {
    super();
    this.store = new AccountsStore(props.api, props.username);
  }

  render() {
    return (
      <ContentWrapper>
        <AccountsList store={this.store}/>
        <EditUser key={this.store.editUser} store={this.store}/>
      </ContentWrapper>
    );
  }
}

export default observer(Accounts);
