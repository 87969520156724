import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Container, Row, Col, Form, FormGroup, Label, Input, Button, FormFeedback} from 'reactstrap';
import MyAccountStore from '../../stores/MyAccountStore';
import StatusMessage from '../../components/StatusMessage';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ContentWrapper from '../ContentWrapper';

class MyAccount extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      callname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      allowPromoMailing: PropTypes.bool
    }).isRequired,
  }

  constructor(props) {
    super();

    this.store = new MyAccountStore(props.api, props.user);
  }

  render() {
    const {callname, email, allowPromoMailing, error, success, validEmail, saveDisabled} = this.store;
    const {username, company} = this.store.user;

    const closeDialog = () => this.store.showDialog(false);
    const onFormChange = ({target}) => this.store.update(target.name, target.value);
    const onCheckChange = ({target}) => this.store.update('allowPromoMailing', target.checked);
    const onButtonClick = (event) => {
      event.preventDefault();
      switch (event.target.name) {
        case 'save':
          this.store.requestSave();
        break;
        case 'resetpw':
          this.store.showDialog(true);
          break;
        default:
          window.history.back();
      }
    }

    return (
      <ContentWrapper>
        <Container>
          <Row className='justify-content-center'>
            <Col md='8' className='myaccount'>
              <h1 className='text-center'>My account</h1>
              <StatusMessage error={error} success={success}/>
              <Form>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    disabled
                    type='text'
                    value={username}/>
                </FormGroup>
                <FormGroup>
                  <Label>Company</Label>
                  <Input
                    disabled
                    type='text'
                    value={company}/>
                </FormGroup>
                <FormGroup>
                  <Label>Full name</Label>
                  <Input
                    invalid={!callname.trim()}
                    type='text'
                    name='callname'
                    autoComplete='name'
                    autoFocus
                    value={callname}
                    onChange={onFormChange}/>
                  <FormFeedback>Name cannot be empty</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>E-mail</Label>
                  <Input
                    invalid={!validEmail}
                    type='text'
                    name='email'
                    autoComplete='email'
                    value={email}
                    onChange={onFormChange}/>
                  <FormFeedback>Invalid e-mail</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <input type='checkbox' name='mailing' id='mailing' checked={!!allowPromoMailing} onChange={onCheckChange}/>
                  <label className='ml-2' htmlFor='mailing'>Notify me via email when new games launch, asset packs become available, new studios join Relax Gaming, and keep me informed of other relevant news.</label>
                </FormGroup>
                <Row>
                  <Col md={4} className='d-flex justify-content-center'>
                    <Button className='m-1' disabled={saveDisabled} name='save'color='primary' onClick={onButtonClick}>Save changes</Button>
                  </Col>
                  <Col md={4} className='d-flex justify-content-center'>
                    <Button className='m-1' name='resetpw' color='warning' onClick={onButtonClick}>Reset password</Button>
                  </Col>
                  <Col md={4} className='d-flex justify-content-center'>
                    <Button className='m-1' name='cancel' color='secondary' onClick={onButtonClick}>Cancel</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <ConfirmationDialog
            title='Reset password'
            msg='Are you sure to reset your password?'
            isOpen={this.store.dialogOpen}
            onOk={this.store.requestResetPw}
            onCancel={closeDialog}/>
        </Container>
      </ContentWrapper>
    );
  }
}

export default observer(MyAccount);
