import {extendObservable, decorate, action, computed} from 'mobx';
import {pickBy, isNil, zipObj} from 'ramda';

class PostEditorStore {
  constructor() {
    extendObservable(this, {
      id: null,
      title: '',
      body: '',
      timestamp: null,
      images: [],
      uploadPercent: null
    });
  }

  create = () => {
    this.id = '';
    this.title = '';
    this.body = '';
    this.timestamp = null;
    this.images = [];
  }

  edit = ({id, title, body, timestamp}) => {
    this.id = id;
    this.title = title;
    this.body = body;
    this.timestamp = timestamp;
    this.images = [];
  }

  close = () => {
    this.id = null;
  }

  setTitle = (title) => {
    this.title = title;
  }

  setBody = (body) => {
    this.body = body;
  }

  setImages = (images) => {
    this.images = images || [];
  }

  setTimestamp = (timestamp) => {
    this.timestamp = timestamp;
  }

  handleProgress = ({direction, percent}) => {
    this.uploadPercent = direction === 'upload' ? percent : null;
  }

  get isOpen() {
    return this.id !== null;
  }

  get header() {
    return !this.id ? 'New post' : 'Edit post';
  }

  get saveDisabled() {
    return !(this.title && this.body) || !!this.uploadPercent;
  }

  get uploadStatus() {
    return this.uploadPercent ? `Uploading... ${parseFloat(this.uploadPercent).toFixed(1)}%` : null
  }

  get uploadContent() {
    if (this.id !== null) {
      const pickValid = pickBy(value => !isNil(value));
      const fields = pickValid({title: this.title, body: this.body, id: this.id || null, timestamp: this.timestamp});
      const files = this.images.length > 0 ? zipObj(['image1', 'image2', 'image3'], this.images) : null;
      return pickValid({fields, files});
    }
    return null;
  }
}

decorate(PostEditorStore, {
  create: action,
  edit: action,
  close: action,

  setTitle: action,
  setBody: action,
  setImages: action,
  setTimestamp: action,

  handleProgress: action,
  handleIdChange: action,

  isOpen: computed,
  header: computed,
  saveDisabled: computed,
  uploadStatus: computed,
  uploadContent: computed
})

export default PostEditorStore;
