/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {omit} from 'ramda';
import {PRIVACY_URL} from '../config'

const Footer = (props) => {
  const rest = omit(['version'], props);
  return (
    <footer className='footer text-center' {...rest}>
      <div>© 2018-{moment().year()} <a href='https://www.relax-gaming.com'>RELAX GAMING GROUP.</a> ALL RIGHTS RESERVED.</div>
      <div><a href={PRIVACY_URL} target='_blank' rel='noopener noreferrer'>Privacy Notice</a></div>
      <div className='version'><div>{props.version}</div></div>
    </footer>
  );
}
Footer.propTypes = {
  version: PropTypes.string
}

export default Footer;
