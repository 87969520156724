/* eslint jsx-a11y/iframe-has-title: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Button} from 'reactstrap';
import {omit} from 'ramda';
import {demoGameUrl, youTubeUrl} from '../../../utils';

const IFrameContainer = (props) => {
  return (
    <div className='demo-wrapper d-none d-md-block mt-1'>
      <Container className='mb-4'>
        <Row>
          <Col className='text-right mt-1'>
            <Button onClick={props.onClose}>×</Button>
          </Col>
        </Row>
        <Row>
          <Col className='col-lg-12 col-md-12 col-sm-12'>
            <div className='embed-responsive embed-responsive-16by9'>
              <iframe
                {...omit(['onClose'], props)}
                className='embed-responsive-item'
                frameBorder='0'
                allowFullScreen>
              </iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
IFrameContainer.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export const GameDemo = ({game, onClose}) => {
  if (game && game.gameName && game.gameid) {
    return (
      <IFrameContainer
        title={game.gameName}
        src={demoGameUrl(game.gameid, 'web')}
        onClose={onClose}/>
    );
  }
  return null;
}
GameDemo.propTypes = {
  game: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export const YouTubePlayer = ({game, onClose}) => {
  if (game && game.gameName && game.videoURL) {
    const url = youTubeUrl(game.videoURL);
    if (url) {
      return (
        <IFrameContainer
          title={game.gameName}
          src={url}
          allow='autoplay; encrypted-media'
          onClose={onClose}/>
      );
    }
  }
  return null;
}
YouTubePlayer.propTypes = {
  game: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default IFrameContainer;
