export const login = (api, username, password, onOk, onError) => {
  const handler = api.createEndHandler('login', onOk, onError, true);
  return api.post('login', null, {username, password}).end(handler);
}

export const logout = (api, onOk, onError) => {
  const handler = api.createEndHandler('logout', onOk, onError);
  return api.get('logout', '').end(handler);
}

export const accorequest = (api, name, email, company, onOk, onError) => {
  const handler = api.createEndHandler('accorequest', onOk, onError);
  return api.post('accorequest', null, JSON.stringify({name, email, company})).end(handler);
}
