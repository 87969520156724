import {extendObservable, decorate, action, computed, toJS} from 'mobx';
import {
  listGames,
  enableGame,
  disableGame,
  enableTestBuild,
  disableTestBuild,
  setPromos,
  addGameAsset,
  deleteGameAsset,
  setGameDescription
} from '../api/Games';
import {getRoadmap, addRoadmap} from '../api/Roadmap';
import moment from 'moment';
import {curry, path, sortWith, ascend, descend, prop, findIndex, propEq, update, without, times, fromPairs, zipObj, mergeDeepLeft, find, sortBy} from 'ramda';

const propAsInt = curry((p, obj) => { return parseInt(path([p], obj)) || 0 })

const sortGames = sortWith([
  descend(prop('oaEnabled')),
  descend(propAsInt('releaseDate')),
  ascend(prop('gameName'))
])

export const createPromoRequest = (carousel, featured) => {
  const trimAndZip = (arr, key) => {
    const ids = without([null, undefined, ''], arr || []);
    const slots = times((n) => fromPairs([
      [key, n + 1]
    ]), ids.length);
    return zipObj(ids, slots);
  };

  const obj = mergeDeepLeft(
    trimAndZip(carousel, 'oaPromoSlot'),
    trimAndZip(featured, 'oaFeatureSlot')
  );

  const arr = Object.keys(obj).map((key) => {
    if (obj.hasOwnProperty(key)) {
      return {...{gameid: key}, ...obj[key]};
    }
    return null;
  });

  return without([null], arr);
}

class GamesStore {
  constructor(api, admin, tsProvider = () => moment().unix()) {
    extendObservable(this, {
      _games: [],
      filter: '',
      roadmap: {},
      promoEdit: false,
      error: '', // error message
      success: '' // success message
    });

    if (typeof tsProvider !== 'function' || typeof tsProvider() !== 'number') {
      throw new Error('tsProvider must be a function returning a number (current unix timestamp)');
    }

    Object.defineProperty(this, 'timestamp', {
      get: function () {
        return tsProvider();
      }
    });

    this.api = api;
    this.admin = admin;
    this.ts = 0;

    // this.timestamp: getter for current timestamp
    // this.ts       : games list timestamp
  }

  // API

  reset = (api, admin) => {
    this.api = api;
    this.admin = admin;
    this._games = [];
    this.filter = '';
    this.promoEdit = false;
    this.error = '';
    this.success = '';
    this.update(true);
  }

  update = (force) => {
    const now = this.timestamp;
    if (force || this._games.length === 0 || now - this.ts > 3 * 60) {
      this.ts = now;
      listGames(this.api, this._onList, this._onError);
    }
  }

  enableGame = (gameid, enable) => {
    const fun = enable ? enableGame : disableGame;
    this._updateGame(gameid, {oaEnabled: enable});
    fun(this.api, gameid, this._onEnable, this._onError);
  }

  enableDemo = (gameid, enable) => {
    const fun = enable ? enableTestBuild : disableTestBuild;
    this._updateGame(gameid, {testBuildEnabled: enable});
    fun(this.api, gameid, this._onEnable, this._onError);
  }

  setPromoEditorVisible = (visible) => {
    this.promoEdit = visible;
  }

  setPromos = (carousel, featured) => {
    const promoReq = createPromoRequest(carousel, featured);
    setPromos(this.api, promoReq, this._onSetPromos, this._onError);
  }

  setGameDescription = (data) => {
    setGameDescription(this.api, data, this._onGamePayload, this._onError);
  }

  addGameAsset = (gameid, assetType, file, onProgress) => {
    const _onProgress = (event) => onProgress({...event, ...{assetType}});
    addGameAsset(this.api, gameid, assetType, file, this._onGamePayload, this._onError, _onProgress);
  }

  deleteGameAsset = (gameid, assetType, screenshotIndex) => {
    deleteGameAsset(this.api, gameid, assetType, screenshotIndex, this._onGamePayload, this._onError);
  }

  getRoadmap = () => {
    getRoadmap(this.api, this._onRoadmap, this._onError);
  }

  addRoadmap = (file, onProgress) => {
    addRoadmap(this.api, file, this._onRoadmap, this._onError, onProgress);
  }

  setFilter = (filter, now) => {
    clearTimeout(this.filterTimeout);

    if (now) {
      this.filter = filter;
    }
    else {
      this.filterTimeout = setTimeout(action(() => {
        this.filter = filter;
      }), 300);
    }
  }

  setStatus = (error, success) => {
    this.error = error || '';
    this.success = success || '';
  }

  // actions

  _onList = (payLoad) => {
    this._games = payLoad.filter(game => !!game.gameName);
    this.error = '';
    this.getRoadmap();
  }

  _onEnable = (payLoad) => {
    this._updateGame(payLoad.gameid, payLoad);
  }

  _onSetPromos = (payLoad) => {
    this._games = toJS(this._games).map(game => {
      const updated = find(propEq('gameid', game.gameid), payLoad);
      return updated || {...game, ...{oaPromoSlot: 0, oaFeatureSlot: 0}};
    });
    this.promoEdit = false;
    this.setStatus('', 'Game promotions updated');
  }

  _onRoadmap = (payLoad) => {
    this.roadmap = payLoad;
  }

  _onGamePayload = (payLoad) => {
    this._updateGame(payLoad.gameid, payLoad);
  }

  _updateGame = (gameid, values) => {
    const index = findIndex(propEq('gameid', gameid), this._games);
    if (index > -1) {
      const game = this._games[index];
      this._games = update(index, {
        ...game,
        ...values
      }, this._games);
    }
  }

  _onError = (error) => {
    this.setStatus(error.msg);
  }

  get games() {
    if (!this.admin) {
      return this._games.filter(({oaEnabled}) => !!oaEnabled);
    }
    return this._games;
  }

  get filteredGames() {
    if (!this.filter) {
      return sortGames(this.games);
    }

    // filter searches only for game name and developer name
    const filteredGames = this.games.filter(({gameName, developer}) => (
      `${gameName} ${developer}`.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
    ));

    return sortGames(filteredGames);
  }

  get carouselGames() {
    const games = this.games.filter(({oaPromoSlot}) => !!oaPromoSlot);
    return sortBy(prop('oaPromoSlot'), games);
  }

  get featuredGames() {
    const games = this.games.filter(({oaFeatureSlot}) => !!oaFeatureSlot);
    return sortBy(prop('oaFeatureSlot'), games);
  }
}
decorate(GamesStore, {
  reset: action,
  setPromoEditorVisible: action,
  setFilter: action,
  setStatus: action,

  _onList: action,
  _onEnable: action,
  _onSetPromos: action,
  _onRoadmap: action,
  _updateGame: action,
  _onError: action,

  games: computed,
  filteredGames: computed,
  carouselGames: computed,
  featuredGames: computed
})

export default GamesStore;
