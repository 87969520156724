/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'reactstrap';
import LoginForm from './LoginForm';
import ChangePasswordForm from './ChangePasswordForm';
import RequestAccountForm from './RequestAccountForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import StatusMessage from '../../components/StatusMessage';
import {publicUrl} from '../../utils';
import {LOGIN_LOGO_URL, LOGIN_BG_URL} from '../../config';
import moment from 'moment';

const LoginFooter = ({mode, callback}) => {
  const _onClick = (action) => (event) => {
    event.preventDefault();
    callback(action);
  }

  const Content = ({title, link, onClick}) => {
    return (
      <div className='text-center request-account'>
        {title}<a onClick={onClick}>{link}</a>
      </div>
    );
  }

  switch (mode) {
    case 'requestaccount':
    case 'forgotpw':
      return (<Content title='Back to ' link='login' onClick={_onClick('')}/>);
    default:
      return (
        <React.Fragment>
          <Content title="Don't have an account? " link='Request here' onClick={_onClick('requestaccount')}/>
          <div className='text-center affiliate-access mt-2 mb-3'>
            <a href='https://client.firstlookgames.com/relax.html'>Access for Affiliates</a>
          </div>
        </React.Fragment>
      );
  }
}

const Content = (props) => {
  switch (props.mode) {
    case 'changepw':
      return (<ChangePasswordForm {...props}/>);
    case 'requestaccount':
      return (<RequestAccountForm {...props}/>);
    case 'forgotpw':
      return (<ForgotPasswordForm {...props}/>);
    default:
      return (<LoginForm {...props}/>)
  }
}

const Login = (props) => {
  const day = moment().date();
  const bgStyle = {
    backgroundImage: `url('${LOGIN_BG_URL}?${day}')`
  }
  const logoUrl = `${LOGIN_LOGO_URL}?${day}`;
  const showStatus = !!(props.error || props.success);

  return (
    <div
      className='d-flex flex-column justify-content-around align-items-center h-100 loginbg'
      style={bgStyle} >
      <div className='flex-1'></div>
      <div className='gamelogo'>
        <img src={logoUrl} alt="Game logo"/>
      </div>
      <div className='login'>
        <Container>
          <Row>
            <Col>
              <div className='logo'>
                <img src={publicUrl('img/logo-white.svg')} alt='logo'/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                showStatus
                  ? <StatusMessage {...props}/>
                  : <div className="loginTitle">Client Area Log In</div>
              }
              <Content {...props}/>
            </Col>
          </Row>
          <LoginFooter {...props}/>
        </Container>
      </div>
      <div className='flex-1'></div>
    </div>
  );
}

Login.propTypes = {
  username: PropTypes.string,
  mode: PropTypes.oneOf(['normal', 'blocked', 'changepw', 'forgotpw', 'requestaccount', '']).isRequired,
  success: PropTypes.string,
  error: PropTypes.string,
  callback: PropTypes.func.isRequired
}

export default Login;
