export const listUsers = (api, onOk, onError) => {
  const handler = api.createEndHandler('users/list', onOk, onError);
  return api.get_dst('users', 'list').end(handler);
}

export const addUser = (api, user, onOk, onError) => {
  const handler = api.createEndHandler('users/add', onOk, onError);
  return api.post_dst('users', 'add', JSON.stringify(user)).end(handler);
}

export const editUser = (api, user, onOk, onError) => {
  const handler = api.createEndHandler('users/edit', onOk, onError);
  return api.post_dst('users', 'edit', JSON.stringify(user)).end(handler);
}

export const resetUserPw = (api, username, onOk, onError) => {
  const handler = api.createEndHandler('users/resetpw', onOk, onError);
  return api.get_dst('users', `resetpw/${username}`).end(handler);
}

export const enableUser = (api, username, onOk, onError) => {
  const handler = api.createEndHandler('users/enable', onOk, onError);
  return api.get_dst('users', `enable/${username}`).end(handler);
}

export const disableUser = (api, username, onOk, onError) => {
  const handler = api.createEndHandler('users/disable', onOk, onError);
  return api.get_dst('users', `disable/${username}`).end(handler);
}

export const deleteUser = (api, username, onOk, onError) => {
  const handler = api.createEndHandler('users/delete', onOk, onError);
  return api.get_dst('users', `delete/${username}`).end(handler);
}

export const changeUserPw = (api, newpassword, onOk, onError) => {
  const handler = api.createEndHandler('users/changepw', onOk, onError);
  return api.post_dst('users', 'changepw', {newpassword}).end(handler);
}

export const forgotpw = (api, username, onOk, onError) => {
  const handler = api.createEndHandler('users/forgotpw', onOk, onError);
  return api.post('users', 'forgotpw', JSON.stringify({username})).end(handler);
}

const Users = {
  list: listUsers,
  add: addUser,
  edit: editUser,
  resetpw: resetUserPw,
  enable: enableUser,
  disable: disableUser,
  delete: deleteUser,
  changepw: changeUserPw
}

export default Users;
