import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Input, Button, Table, Badge} from 'reactstrap';
import DetailsForm from './DetailsForm';
import {last, fromPairs} from 'ramda';
import {publicUrl} from '../../../utils';

const assetData = [
  { key: 'mathDoc', description: 'Math doc', limits: 'pdf', accept: 'application/pdf' },
  { key: 'marketingMaterial', description: 'Asset pack', limits: 'zip', accept: 'application/zip' },
  { key: 'gamesheet', description: 'Game sheet', limits: 'pdf, zip', accept: 'application/pdf,application/zip' },
  { key: 'gamerules', description: 'Game rules', limits: 'zip', accept: 'application/zip' },
  { key: 'gamecertificate', description: 'Game certificate', limits: 'pdf, zip, png, jpg', accept: 'application/pdf,application/zip,image/png,image/jpeg' },
  { key: 'helpfile', description: 'Help file', limits: 'pdf', accept: 'application/pdf' },
  { key: 'thumbnail1x', description: 'Small thumbnail used in various places', limits: '300x300px png', accept: 'image/png' },
  { key: 'thumbnail2x', description: 'Gamepage main logo', limits: '600x600px png', accept: 'image/png' },
  { key: 'bannerimage', description: 'Large banner for potential main promo carousel', limits: '1600x755px jpg', accept: 'image/jpeg' },
  { key: 'bannerimageSmall', description: '"Featured" promo banner and regular game listing image', limits: '810x382px jpg', accept: 'image/jpeg' }
  // screenshots are done separately because it's an array
];

const AssetFilePicker = observer(class AssetFilePicker extends React.Component {
  static propTypes = {
    accept: PropTypes.string,
    preventUpload: PropTypes.bool,
    addAsset: PropTypes.func
  }
  static defaultProps = {
    accept: '',
    preventUpload: false
  }

  constructor() {
    super();

    this.ref = React.createRef();
    this.onChooseFile = this.onChooseFile.bind(this);
  }

  onChooseFile(event) {
    event.preventDefault();
    const file = this.ref.current.files[0];
    if (file) {
      this.props.addAsset(file);
    }
  }

  render() {
    const {addAsset, accept, preventUpload} = this.props;
    if (!addAsset || preventUpload) {
      return (<div/>);
    }
    return (
      <label className='btn' style={{marginBottom: 0}}>
        <Input
          style={{display: 'none'}}
          accept={accept}
          innerRef={this.ref}
          type='file'
          color='primary'
          onChange={this.onChooseFile}/>
        <span className='fas fa-cloud-upload-alt'></span>
      </label>
    );
  }
})

const AssetPreview = observer(({url}) => {
  const ext = last(url.split('.')).toLowerCase();
  if (ext === 'zip') {
    return (<span className='fas fa-file-archive'/>);
  }
  else if (ext === 'pdf') {
    return (<span className='fas fa-file-pdf'/>);
  }
  else if (['jpg', 'png', 'jpeg'].indexOf(ext) !== -1) {
    return !!url ? <img style={{maxHeight: '32px'}} src={url} alt={''}/> : '-';
  }
  return null;
})

const Asset = observer(({data, value, addAsset, deleteAsset, descRowSpan, preventUpload, uploaded}) => {
  const descCol = descRowSpan > 0 ? (<td rowSpan={descRowSpan}>{data ? data.description : ''}</td>) : null;
  const controlsClassName = uploaded ? 'd-none' : '';
  const uploadClassName = uploaded ? 'text-center' : 'd-none';
  return (
    <tr>
      {descCol}
      <td>{data.limits}</td>
      <td className={uploadClassName} colSpan={3}><span>Uploading... {parseFloat(uploaded).toFixed(1)}%</span></td>
      <td className={controlsClassName}><AssetPreview url={publicUrl(value)}/></td>
      <td className={controlsClassName}><AssetFilePicker value={value} accept={data.accept} addAsset={addAsset} preventUpload={preventUpload}/></td>
      <td className={controlsClassName}>{!!value && <Button color='danger' onClick={() => { deleteAsset() }}><span className='fas fa-trash-alt'/></Button>}</td>
    </tr>
  );
})
Asset.defaultProps = {
  data: {
    description: '',
    limits: '',
    accept: ''
  },
  descRowSpan: 1,
  preventUpload: false
}

const EditorSection = observer(({children, title, contentClassName = ''}) => {
  return (
    <div className='editorsection'>
      <div className='editorsection-title'>{title}</div>
      <div className={contentClassName}>
        {children}
      </div>
    </div>
  );
})

const generateScreenshots = (screenshots, addAsset, deleteAsset) => {
  const count = screenshots.length;
  const header = (
    <tr key='screenshots'>
      <td>Screenshots (max. 5)</td>
      <td>1600x900px jpg</td>
      <td></td>
      <td><AssetFilePicker accept='image/jpeg' addAsset={count > 4 ? null : addAsset}/></td>
      <td></td>
    </tr>
  );

  const rows = screenshots.map((url, index) => {
    const del = () => deleteAsset(index);
    return (
      <Asset
        data={{description: `Screenshot ${index + 1}`}}
        key={index}
        value={url}
        addAsset={addAsset}
        deleteAsset={del}
        preventUpload/>
    );
  });

  return [header].concat([rows]);
}

const Toggler = observer(({title, value, onClick}) => {
  return (
    <div className='mb-2'>
      {title}
      <Badge
        className='ml-2'
        color={value ? 'success' : 'danger'}>
          {value ? 'enabled' : 'disabled'}
      </Badge>
      <Button
        className='float-right'
        color={value ? 'danger' : 'success'}
        size='sm'
        onClick={onClick}>
          {`${value ? 'Disable' : 'Enable'} ${title.toLowerCase()}`}
      </Button>
    </div>
  );
})

class GameAssetEditor extends React.Component {
  constructor() {
    super();

    this.state = {}
  }

  onUploadProgress = ({direction, percent, assetType}) => {
    if (direction === 'upload') {
      this.setState(fromPairs([[assetType, percent]]));
    }
    else {
      this.setState(fromPairs([[assetType, null]]));
    }
  }

  render() {
    const {game, store} = this.props;

    if (!store.admin) {
      return null;
    }

    const addGameAsset = (type) => (file) => {
      store.addGameAsset(game.gameid, type, file, this.onUploadProgress);
    }

    const deleteGameAsset = (type) => (screenshotIndex) => {
      store.deleteGameAsset(game.gameid, type, screenshotIndex);
    }

    const saveForm = (data) => {
      store.setGameDescription(data);
    }

    const toggleGame = () => store.enableGame(game.gameid, !game.oaEnabled);
    const toggleDemo = () => store.enableDemo(game.gameid, !game.testBuildEnabled);

    const assets = assetData.map((data) => {
      const value = (game && game[data.key]) || '';
      return (
        <Asset
          key={data.key}
          data={data}
          value={value}
          addAsset={addGameAsset(data.key)}
          deleteAsset={deleteGameAsset(data.key)}
          uploaded={this.state[data.key]}/>);
    });

    const screenshots = generateScreenshots(game.screenshots || [], addGameAsset('screenshot'), deleteGameAsset('screenshot'));

    const directUrl = publicUrl(`?g=${game.gameid}`)

    return (
      <div>
        <div className='asset-editor-divider text-center'><span>Admin area. Be careful below this line!</span></div>
        <EditorSection title='Direct Link'>
          <div><a href={directUrl}>{directUrl}</a></div>
        </EditorSection>
        <EditorSection title='Activation'>
          <Toggler title='Game listing' value={game.oaEnabled} onClick={toggleGame}/>
          <Toggler title='Demo play' value={game.testBuildEnabled} onClick={toggleDemo}/>
        </EditorSection>
        <EditorSection title='Game Details'>
          <DetailsForm game={game} onSave={saveForm}/>
        </EditorSection>
        <EditorSection title='Files' contentClassName='files'>
          <Table responsive>
            <thead>
              <tr>
                <td><strong>Description</strong></td>
                <td><strong>Type/size</strong></td>
                <td><strong>Current</strong></td>
                <td colSpan={2}><strong>Actions</strong></td>
              </tr>
            </thead>
            <tbody>
              {assets}
              {screenshots}
            </tbody>
          </Table>
        </EditorSection>
      </div>
    );
  }
}

export default observer(GameAssetEditor);
