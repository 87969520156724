import {extendObservable, decorate, action, reaction, computed, runInAction} from 'mobx';
import {createAPI, requestAPI} from '../api/common';
import {logout} from '../api/Login';
import {enableUnloadConfirmation} from '../utils';
import {STARTPAGE} from '../config';

export const AppStatus = Object.freeze({
  START: 0,
  LOGIN: 1,
  CONSENT_REQUIRED: 2,
  LOGGED_IN: 3
})

class AppStore {
  constructor(loggedInCallback) {
    extendObservable(this, {
      user: null,
      error: '',
      serverVersion: '',
      api: null,
      currentPage: STARTPAGE
    });

    // when userStatus changes to 'normal', call loggedInCallback and set start page
    reaction(
      () => this.userStatus === 'normal',
      (userStatusNormalized) => {
        if (userStatusNormalized) {
          loggedInCallback();

          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has('g')) {
            this.navigateTo(['games', urlParams.get('g')].join(':'));
          }
          else {
            this.navigateTo(STARTPAGE);
          }
        }
      }
    );

    // when username changes, add onpopstate handler
    reaction(
      () => this.username,
      (username) => {
        if (username) {
          window.onpopstate = (event) => {
            const {state} = event;
            if (state && state.page && state.username === username) {
              runInAction(() => {
                this.currentPage = state.page;
              });
            }
          };
        }
      }
    );

    // when logged in as admin, enable unload confirmation
    reaction(
      () => this.admin,
      (admin) => enableUnloadConfirmation(admin)
    );
  }

  initialize = () => {
    requestAPI(this._onAPI, this._onAPIError);
  }

  _onAPI = (payLoad) => {
    this.api = createAPI(payLoad, this._unauthCallback, this._okFallback, this._errorFallback);
    this.user = payLoad.user;
    this.serverVersion = payLoad.serverVersion;
  }

  _onAPIError = (error) => {
    console.error('Failed to initialize API:', error.msg);
    this.api = null;
    this.error = error.msg;
  }

  _unauthCallback = () => {
    // if userdata still exists when we get 401, it means the session has expired
    if (this.user && this.user.username) {
      this.logout('Session expired');
    }
  }

  _okFallback = (tag, payLoad) => {
    switch(tag) {
      case 'login':
        this.api.dstoken = payLoad.doubleSubmitToken;
        this.user = payLoad;
        break;
      case 'users/edit':
        if (this.username === payLoad.username) {
          this.user = {...this.user, ...payLoad};
        }
        break;
      case 'logout':
        this.logout();
        break;
      default:
    }
  }

  _errorFallback = (tag, error) => {
    switch(tag) {
      case 'logout':
        this.logout();
        break;
      default:
    }
  }

  navigateTo = (page) => {
    if (this.status !== AppStatus.LOGGED_IN) {
      return;
    }

    if (page === 'logout') {
      delete window.onpopstate;
      logout(this.api); // response will be handled in App.okFallback/App.errorFallback
    }
    else if (page !== this.currentPage) {
      window.history.pushState({page, username: this.username}, '');
      this.currentPage = page;
    }
  }

  logout = (error = '') => {
    this.user = null;
    this.error = error;
  }

  setError = (msg = '') => {
    this.error = msg;
  }

  get admin() {
    return this.user ? this.user.role === 'admin' : false;
  }

  get username() {
    return this.user ? this.user.username : '';
  }

  get userStatus() {
    return this.user ? this.user.userStatus : '';
  }

  get status() {
    if (this.serverVersion && this.api) {
      if (this.userStatus === 'normal') {
        if (!this.user.privacyNoticeAccepted) {
          return AppStatus.CONSENT_REQUIRED;
        }

        return AppStatus.LOGGED_IN;
      }

      return AppStatus.LOGIN;
    }

    return AppStatus.START;
  }
}
decorate(AppStore, {
  _onAPI: action,
  _onAPIError: action,
  _unauthCallback: action,
  _okFallback: action,
  _errorFallback: action,

  navigateTo: action,
  logout: action,
  setError: action,

  admin: computed,
  username: computed,
  userStatus: computed,
  status: computed
})

export default AppStore;
