export const contacts = (api, onOk, onError) => {
  const handler = api.createEndHandler('contact', onOk, onError);
  return api.get_dst('contact').end(handler);
}

export const setContacts = (api, contacts, onOk, onError) => {
  const handler = api.createEndHandler('contact/set', onOk, onError);
  return api.post_dst('contact', 'set', JSON.stringify(contacts)).end(handler);
}

export const submit = (api, name, email, message, onOk, onError) => {
  const handler = api.createEndHandler('contact/submit', onOk, onError);
  const content = JSON.stringify({name, email, message});
  return api.post_dst('contact', 'submit', content).end(handler);
}
