import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, FormFeedback, Button} from 'reactstrap';
import {isValidEmail} from '../../utils';
import {fromPairs} from 'ramda';

class RequestAccountForm extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    requesting: PropTypes.bool.isRequired
  }

  constructor(props) {
    super();

    this.state = {
      name: '',
      company: '',
      email: ''
    };
  }

  onInputChange = ({target}) => {
    this.setState(fromPairs([[target.name, target.value]]));
  }

  onSubmit = (event) =>  {
    event.preventDefault();
    this.props.callback('requestaccount', this.state);
  }

  render() {
    const {name, company, email} = this.state;
    const validEmail = isValidEmail(email);
    const invalidEmail = email !== '' && !validEmail;
    const disabled = this.props.requesting || !(name && company && validEmail);

    return (
      <Form onSubmit={this.onSubmit}>
        <Input
          name='name'
          type='text'
          id='inputName'
          placeholder='Name'
          autoComplete='username'
          autoCorrect='off'
          spellCheck='false'
          required
          autoFocus
          value={name}
          className='mb-1'
          onChange={this.onInputChange}/>
        <Input
          name='company'
          type='text'
          id='inputCompany'
          placeholder='Company'
          autoComplete='organization'
          autoCorrect='off'
          spellCheck='false'
          required
          value={company}
          className='mb-1'
          onChange={this.onInputChange}/>
        <Input
          name='email'
          type='text'
          id='inputEmail'
          placeholder='E-mail'
          autoComplete='email'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          required
          value={email}
          className='mb-1'
          invalid={invalidEmail}
          onChange={this.onInputChange}/>
        <FormFeedback>Invalid e-mail</FormFeedback>
        <Button
          color='primary'
          size='lg'
          block
          disabled={disabled}
          type='submit'>Request Account</Button>
      </Form>
    );
  }
}

export default RequestAccountForm;
