import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Button} from 'reactstrap';
import NewsStore from '../../stores/NewsStore';
import Post from './Post';

const NewsItem = (props) => {
  const {store, id} = props;

  const onEditClick = (event) => {
    event.stopPropagation();
    props.store.editPost(id);
  }

  const onDeleteClick = (event) => {
    event.stopPropagation();
    props.store.setDeletePost(id);
  }

  const onSelectClick = () => store.selectPost(id);

  const buttons = store.admin
    ? (<div className='text-right'>
        <Button className='m-1 btn-sm' color='secondary' onClick={onEditClick}>Edit</Button>
        <Button className='m-1 btn-sm' color='danger' onClick={onDeleteClick}>Delete</Button>
      </div>)
    : null;

  return (
    <Post {...props} onClick={onSelectClick}>
      {buttons}
    </Post>
  );

}

NewsItem.propTypes = {
  timestamp: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  images: PropTypes.array,
  store: PropTypes.instanceOf(NewsStore).isRequired
}

export default observer(NewsItem);
