export const LIST_ITEMS_PER_PAGE = 10;

export const CAROUSEL_PROMO_SLOTS = 3;
export const FEATURED_PROMO_SLOTS = 4;
export const MAX_PROMO_SLOTS = CAROUSEL_PROMO_SLOTS + FEATURED_PROMO_SLOTS;

export const NEWS_DEFAULT_DAYS_SHOWN = 30;

export const STARTPAGE = 'games';

export const AUTOLOGIN_USERNAME = '';
export const AUTOLOGIN_PASSWORD = '';

export const LOGIN_ASSETS_BASE_URL = 'https://cf-cdn.relax-gaming.com/uploads/files';
export const LOGIN_LOGO_URL = `${LOGIN_ASSETS_BASE_URL}/clientarea-logo.png`;
export const LOGIN_BG_URL = `${LOGIN_ASSETS_BASE_URL}/clientarea-bg.jpg`;

export const DEMOURL = 'https://d2drhksbtcqozo.cloudfront.net/casino/launcher.html';

export const CATALOGUE_URL = 'https://d2drhksbtcqozo.cloudfront.net/docs/Relax-Catalogue.xlsx';
export const PRIVACY_URL = 'https://relax-gaming.com/docs/Privacy-Notice-Client-Area-2.0.pdf';
export const ROADMAP_HIGHLIGHTS_URL = 'https://relax-gaming.com/docs/Relax-Roadmap-Highlights.pdf';

export const GOOGLE_TRACKING_ID = 'UA-68266953-11';
// export const GOOGLE_TRACKING_ID = '';

export const UserRoles = {
  user: 'User',
  admin: 'Admin'
}

export const UserStatuses = {
  normal: 'Normal',
  blocked: 'Blocked',
  changepw: 'Change password'
}
