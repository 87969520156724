/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import NewsStore from '../../stores/NewsStore';
import moment from 'moment';

const PostTitle = observer(({title, onClick}) => {
  return (
    <div className='text-truncate my-2'>
      <a onClick={onClick}>{title}</a>
    </div>
  );
})

const PostPicker = ({store}) => {
  const onClick = (id) => (event) => {
    event.preventDefault();
    store.selectPost(id);
  };

  const content = store.postLinks.map(({id, title}) => (
    <PostTitle key={id} title={title} onClick={onClick(id)}/>
  ));

  const title = moment(store.selectedMonth || moment().unix(), 'X').format('MMMM YYYY');

  return (
    <div className='mb-4'>
      <h2>{title}</h2>
      <div className='sidebar-items mt-3'>
        {content}
      </div>
    </div>
  );
}

PostPicker.propTypes = {
  store: PropTypes.instanceOf(NewsStore).isRequired
}

export default observer(PostPicker);
