import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import GameList from './GameList';
import GamesStore from '../../stores/GamesStore';

class GamesContainer extends React.Component {
  static propTypes = {
    store: PropTypes.instanceOf(GamesStore),
    selected: PropTypes.string,
    navigateTo: PropTypes.func.isRequired
  }

  constructor(props) {
    super();

    this.state = {
      selected: props.selected,
    }
  }

  componentDidMount() {
    this.props.store.update();
  }

  componentWillUnmount() {
    this.props.store.setFilter('', true);
  }

  onSelectGame = (gameid) => {
    this.props.store.setStatus();
    this.props.navigateTo(['games', gameid].join(':'));
  }

  render() {
    return (
      <GameList
        store={this.props.store}
        selected={this.props.selected}
        selectGame={this.onSelectGame}/>
    );
  }
}

export default observer(GamesContainer);
