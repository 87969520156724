import React from 'react';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import {Container, Row, Col} from 'reactstrap';
import {ROADMAP_HIGHLIGHTS_URL} from '../../config';

const RoadmapHighlights = () => {
  const onDownload = () => {
    ReactGA.event({
      category: 'RoadmapHighlights',
      action: 'Download'
    });
  };

  return (
    <div className='roadmap-highlights'>
      <Container>
        <Row className='pt-5 pb-5'>
          <Col className='col-md-6 offset-md-3 text-center'>
            <h2>Roadmap Highlights</h2>
            <p>Click the button below to download our roadmap highlights</p>
            <a onClick={onDownload} className='btn btn-lg' download href={ROADMAP_HIGHLIGHTS_URL}>Click Here</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default observer(RoadmapHighlights);
