import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, FormFeedback, Button} from 'reactstrap';
import {isValidEmail} from '../../utils';

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    requesting: PropTypes.bool.isRequired
  }

  constructor(props) {
    super();

    this.state = {
      username: ''
    };
  }

  onInputChange = ({target}) => {
    this.setState({username: target.value});
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.callback('forgotpw', this.state);
  }

  render() {
    const {username} = this.state;
    const validUsername = isValidEmail(username);
    const invalidEmail = username !== '' && !validUsername;

    return (
      <Form onSubmit={this.onSubmit}>
        <Input
          name='username'
          type='text'
          id='inputEmail'
          placeholder='Username (e-mail)'
          autoComplete='email'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          required
          autoFocus
          value={username}
          className='mb-1'
          invalid={invalidEmail}
          onChange={this.onInputChange}/>
        <FormFeedback>Invalid e-mail</FormFeedback>
        <Button
          ref={this.buttonRef}
          color='primary'
          size='lg'
          block
          type='submit'
          disabled={this.props.requesting}>Request Password</Button>
      </Form>
    );
  }
}

export default ForgotPasswordForm;
