import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Navigation from './Navigation';
import {without} from 'ramda';

const NavigationContainer = (props) => {
  const callback = (action, params) => {
    switch(action) {
      case 'navigate':
        props.navigateTo(params);
        break;
      default:
    }
  }

  const admin = props.user.role === 'admin';
  const pages = without([null], [
    'Games',
    'News',
//    'Promotions',
//    'Roadmap',
//    'Documents',
    'Contact',
    admin ? 'Accounts' : null,
    admin ? 'Tools' : null
  ]);

  return (<Navigation pages={pages} {...props} callback={callback}/>);
}

NavigationContainer.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  user: PropTypes.shape({
    company: PropTypes.string.isRequired,
    callname: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired
}

export default observer(NavigationContainer);
