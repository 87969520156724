import React from 'react';
import PropTypes from 'prop-types';
import {Carousel, CarouselItem, CarouselIndicators, CarouselControl} from 'reactstrap';
import {equals, clamp, without} from 'ramda';

class ImageCarouselActual extends React.Component {
  static propTypes = {
    urls: PropTypes.arrayOf(PropTypes.string).isRequired,
    alt: PropTypes.string.isRequired
  }

  constructor() {
    super();

    this.state = {
      activeIndex: 0,
      items: []
    };

    this.refreshTimer = this.refreshTimer.bind(this);
    this.move = this.move.bind(this);
    this.next = this.move(1).bind(this);
    this.previous = this.move(-1).bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.onIndexClicked = this.onIndexClicked.bind(this);
  }

  componentDidMount() {
    this.refreshItems();
    this.refreshTimer();
  }

  componentDidUpdate(prevProps) {
    if (!equals(prevProps.games, this.props.games)) {
      this.refreshItems();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  refreshTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.next, 5000);
  }

  refreshItems() {
    const items = (this.props.urls).map((url, index) =>
      ({
        src: url,
        altText: `${this.props.alt} ${index + 1}`
      })
    );

    this.slides = items.map((item) => {
      return (
        <CarouselItem
          key={item.altText}
          onExiting={this.onExiting}
          onExited={this.onExited}>
          <img className='d-block w-100' src={item.src} alt={item.altText}/>
        </CarouselItem>
      );
    });

    this.setState({
      activeIndex: clamp(0, items.length - 1, this.state.activeIndex),
      items
    });
  }

  move(delta) {
    return () => {
      if (!this.slides || this.animating) {
        return;
      }
      const len = this.slides.length;
      const activeIndex = (this.state.activeIndex + len + delta) % len;
      this.setState({activeIndex});
      this.refreshTimer();
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  onIndexClicked(activeIndex) {
    if (this.animating) {
      return;
    }

    this.setState({activeIndex});
    this.refreshTimer();
  }

  render() {
    const {controls} = this.props;
    const {items, activeIndex} = this.state;
    if (!items || items.length < 2) {
      return <div/>;
    }

    return (
      <Carousel
        next={this.next}
        previous={this.previous}
        ride='carousel'
        activeIndex={activeIndex}
        items={items}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.onIndexClicked}/>
        {this.slides}
        {controls && <CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous}/>}
        {controls && <CarouselControl direction='next' directionText='Next' onClickHandler={this.next}/>}
      </Carousel>
    );
  }
}

const ImageCarousel = (props) => {
  const urls = without([null, undefined, ''], props.urls || []);

  if (urls.length > 1) {
    return (<ImageCarouselActual urls={urls} alt={props.alt} controls={props.controls}/>);
  }

  if (urls.length === 1) {
    return (<img src={urls[0]} className='d-block w-100' alt={props.alt}/>);
  }

  return (<div/>);
}
ImageCarousel.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string),
  alt: PropTypes.string.isRequired,
  controls: PropTypes.bool
}
ImageCarousel.defaultProps = {
  controls: true
}

export default ImageCarousel;
