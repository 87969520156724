import {pickBy, isNil} from 'ramda';

export const listGames = (api, onOk, onError) => {
  const handler = api.createEndHandler('games/list', onOk, onError);
  return api.get_dst('games', 'list').end(handler);
}

export const setPromos = (api, promos, onOk, onError) => {
  const handler = api.createEndHandler('games/set', onOk, onError);
  return api.post_dst('games', 'set', JSON.stringify(promos)).end(handler);
}

export const enableGame = (api, gameid, onOk, onError) => {
  const handler = api.createEndHandler('games/enable', onOk, onError);
  return api.get_dst('games', `enable/${gameid}`).end(handler);
}

export const disableGame = (api, gameid, onOk, onError) => {
  const handler = api.createEndHandler('games/disable', onOk, onError);
  return api.get_dst('games', `disable/${gameid}`).end(handler);
}

export const enableTestBuild = (api, gameid, onOk, onError) => {
  const handler = api.createEndHandler('games/enableTestBuild', onOk, onError);
  return api.get_dst('games', `enableTestBuild/${gameid}`).end(handler);
}

export const disableTestBuild = (api, gameid, onOk, onError) => {
  const handler = api.createEndHandler('games/disableTestBuild', onOk, onError);
  return api.get_dst('games', `disableTestBuild/${gameid}`).end(handler);
}

export const setGameDescription = (api, data, onOk, onError) => {
  const handler = api.createEndHandler('games/setGameDescription', onOk, onError);
  return api.post_dst('games', 'setGameDescription', JSON.stringify(data)).end(handler);
}

const validateAssetType = (assetType) => assetType === 'screenshots' ? 'screenshot' : assetType;

export const addGameAsset = (api, gameid, assetType, file, onOk, onError, onProgress) => {
  const handler = api.createEndHandler('games/setGameAsset', onOk, onError);
  const content = {
    fields: {
      action: 'add',
      gameid,
      assetType: validateAssetType(assetType)
    },
    files: [file]
  };
  return api.post_mp_dst('games', 'setGameAsset', content, onProgress).end(handler);
}

export const deleteGameAsset = (api, gameid, assetType, screenshotIndex, onOk, onError) => {
  const handler = api.createEndHandler('games/setGameAsset', onOk, onError);
  const fields = {
    action: 'delete',
    gameid,
    assetType: validateAssetType(assetType),
    screenshotIndex
  };
  const content = {
    fields: pickBy((val) => !isNil(val), fields),
    files: []
  };

  return api.post_mp_dst('games', 'setGameAsset', content).end(handler);
}
