import React from 'react';
import {configure} from 'mobx';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import PageContainer from './views/PageContainer';
import GamesStore from './stores/GamesStore';
import AppStore,{AppStatus} from './stores/AppStore';
import LoginContainer from './views/login/LoginContainer';
import ProgressSpinner from './components/ProgressSpinner';
import {GOOGLE_TRACKING_ID} from './config';

configure({enforceActions: 'always'});

class App extends React.Component {
  constructor() {
    super();

    this.appStore = new AppStore(this.initGamesStore);
    this.appStore.initialize();

    if (GOOGLE_TRACKING_ID) {
      ReactGA.initialize(GOOGLE_TRACKING_ID);
    }
  }

  initGamesStore = () => {
    if (!this.gamesStore) {
      this.gamesStore = new GamesStore(this.appStore.api, this.appStore.admin);
      this.gamesStore.update();
    }
    else {
      this.gamesStore.reset(this.appStore.api, this.appStore.admin);
    }
  }

  render() {
    if (this.appStore.status === AppStatus.LOGIN) {
      const username = this.appStore.username;
      const userStatus = this.appStore.userStatus;
      return (
        <LoginContainer
          api={this.appStore.api}
          user={{username, userStatus}}
          clearAppError={this.appStore.setError}
          error={this.appStore.error}/>
      );
    }
    else if (this.appStore.status !== AppStatus.START) {
      return (
        <PageContainer appStore={this.appStore} gamesStore={this.gamesStore}/>
      );
    }

    return (
      <div className='m-4 p-4 text-center'>
        {this.appStore.error || <ProgressSpinner/>}
      </div>
    );
  }
}

export default observer(App);
