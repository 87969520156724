import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

const ConfirmationDialog = ({isOpen, title, msg, okText, cancelText, onOk, onCancel}) => {
  const okFunc = onOk || (() => {});
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{msg}</ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={okFunc}>{okText || 'Yes'}</Button>
        <Button color='secondary' onClick={onCancel}>{cancelText || 'No'}</Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func.isRequired
}

export default ConfirmationDialog;
