import {update, equals, sortBy, prop, take, repeat, fromPairs} from 'ramda';
import {CAROUSEL_PROMO_SLOTS, FEATURED_PROMO_SLOTS} from '../../../config';

export const setSlot = (state, type, index, gameid) => {
  if (!gameid || gameid.trim() === '') {
    const slots = update(index, null, state[type]);
    return fromPairs([[type, slots]]);
  }
  else {
    // when changing a slot, make sure to clear other slots with the same gameid
    const slots = state[type].map((exGameId, exIndex) => {
      if (index === exIndex) {
        // set new gameid
        return gameid;
      }
      else if (exGameId === gameid) {
        // reset any other indices having our gameid
        return null;
      }
      // keep existing gameid
      return exGameId;
    });
    return fromPairs([[type, slots]]);
  }
}

export const getSortedPromoIds = (key, games) => {
  const sortByKey = sortBy(prop(key));
  return sortByKey(games.filter(game => !!game[key]));
}

export const updateSlots = (previousGames, currentGames) => {
  const promoFilter = ({oaPromoSlot, oaFeatureSlot}) => !!oaPromoSlot || !!oaFeatureSlot;
  const prevPromos = (previousGames || []).filter(promoFilter);
  const currentPromos = (currentGames || []).filter(promoFilter);

  if (!equals(prevPromos, currentPromos)) {
    const carouselGames = getSortedPromoIds('oaPromoSlot', currentPromos);
    const featuredGames = getSortedPromoIds('oaFeatureSlot', currentPromos);

    const padAndTrim = (arr, num) => take(num, arr.concat(repeat(null, num)));

    const carousel = padAndTrim(carouselGames.map(({gameid}) => gameid), CAROUSEL_PROMO_SLOTS);
    const featured = padAndTrim(featuredGames.map(({gameid}) => gameid), FEATURED_PROMO_SLOTS);

    return {
      carousel,
      featured
    };
  }
}
