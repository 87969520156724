export const getRoadmap = (api, onOk, onError) => {
  const handler = api.createEndHandler('roadmap', onOk, onError);
  return api.get_dst('roadmap').end(handler);
}

export const addRoadmap = (api, file, onOk, onError, onProgress) => {
  const handler = api.createEndHandler('roadmap/add', onOk, onError);
  const content = {
    files: [file]
  };
  return api.post_mp_dst('roadmap', 'add', content, onProgress).end(handler);
}
