import {createLogger, LOG_REQUESTHANDLER} from '../logger';
import {pickBy, values, isNil, omit, keys, type, assoc} from 'ramda';

const rhlogger = createLogger('RequestHandler', LOG_REQUESTHANDLER);

export default class RequestHandler {
  constructor(stateFunc, interval) {
    this.requests = {};
    this.interval = interval || 100;
    this.stateFunc = stateFunc;
    this.requesting = false;
    this.timeout = null;
  }

  cleanup() {
    this.requests = pickBy((req) => (req.xhr.status === 0), this.requests);
  }

  count() {
    this.cleanup();
    return values(this.requests).length;
  }

  abort(id) {
    this.cleanup();
    if (isNil(id)) {
      const reqs = values(this.requests);
      if (reqs.length > 0) {
        rhlogger.log('Aborting all requests');
        reqs.forEach((req) => req.abort());
      }
      this.requests = {};
    }
    else {
      const req = this.requests[id];
      if (req) {
        req.abort();
        this.requests = omit([id], this.requests);
        rhlogger.log(`Aborted request ${id}. Pending: ${keys(this.requests).join(', ')}`);
      }
    }
    return this.requests;
  }

  unmount() {
    this.abort();
    this.stateFunc = () => {};
    clearTimeout(this.timeout);
  }

  add(id, request) {
    if (!id) {
      throw new Error('Invalid id');
    }

    this.cleanup();

    if (request && type(request.abort) === 'Function') {
      this.requests = assoc(id, request, this.abort(id));
      rhlogger.log(`Added request ${id}. Pending: ${keys(this.requests).join(', ')}`);
    }

    if (this.stateFunc) {
      this.refreshTimeout();
    }
  }

  refreshTimeout() {
    clearTimeout(this.timeout);
    const requesting = this.count() > 0;
    if (requesting) {
      setTimeout(() => {
        if (requesting !== this.requesting) {
          this.requesting = requesting;
          this.stateFunc({requesting});
        }
        this.refreshTimeout();
      }, this.interval);
    }
    else {
      if (requesting !== this.requesting) {
        this.requesting = requesting;
        this.stateFunc({requesting});
      }
    }
  }
}
