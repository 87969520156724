/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Button} from 'reactstrap';
import {AUTOLOGIN_USERNAME, AUTOLOGIN_PASSWORD} from '../../config';
import {fromPairs} from 'ramda';

class LoginForm extends React.Component {
  static propTypes = {
    username: PropTypes.string,
    callback: PropTypes.func.isRequired  }

  constructor(props) {
    super();

    this.state = {
      username: props.username || AUTOLOGIN_USERNAME || '',
      password: AUTOLOGIN_PASSWORD || ''
    };

    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();

    // autologin if username & password are set
    if (this.state.username && this.state.password) {
      this.componentDidMount = () => {
        this.requestLogin();
        this.componentDidMount = () => undefined;
      }
    }
  }

  componentDidUpdate() {
    if (this.usernameRef.current) {
      if (!this.state.password) {
        const {username} = this.state;
        // if both fields are empty, focus the username field
        if (!username) {
          this.usernameRef.current.focus();
        }
        // if typed non-empty username is the same one as given in props (after password reset)
        // and password is empty, focus the password field
        else if (username && username === this.props.username) {
          this.passwordRef.current.focus();
        }
      }
    }
  }

  onInputChange = (event) => {
    const {name, value} = event.target;
    const state = fromPairs([[name, value]]);
    this.setState(state);
  }

  requestLogin = () => {
    this.props.callback('login', this.state);
  }

  onForgotClicked = (event) => {
    event.preventDefault();
    this.props.callback('forgotpw');
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.name === 'username') {
      event.preventDefault();
      if (this.state.username) {
        this.passwordRef.current.focus();
      }
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.password) {
      this.requestLogin();
    }
  }

  render() {
    const {username, password} = this.state;
    const disabled = this.props.requesting;

    return (
      <Form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>
        <Input
          disabled={disabled}
          innerRef={this.usernameRef}
          type='text'
          id='inputUsername'
          className='mb-3'
          name='username'
          placeholder='Username'
          autoComplete='username'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          required
          autoFocus
          value={username}
          onChange={this.onInputChange}/>
        <Input
          innerRef={this.passwordRef}
          disabled={disabled}
          type='password'
          id='inputPassword'
          name='password'
          placeholder='Password'
          autoComplete='current-password'
          required
          value={password}
          onChange={this.onInputChange}/>
        <div className='mb-0 mt-2 text-left'>
          <a href='' className='forgot' onClick={this.onForgotClicked}>Forgot password?</a>
        </div>
        <Button
          color='primary'
          size='lg'
          block
          disabled={disabled}
          type='submit'>Login</Button>
      </Form>
    );
  }
}

export default LoginForm;
