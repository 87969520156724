import React from 'react';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import {Container, Row, Col} from 'reactstrap';
import {CATALOGUE_URL} from '../../config';

const Catalogue = () => {
  const onDownload = () => {
    ReactGA.event({
      category: 'Catalogue',
      action: 'Download'
    });
  };

  return (
    <div className='catalogue'>
      <Container>
        <Row className='pt-5 pb-5'>
          <Col className='col-md-6 offset-md-3 text-center'>
            <h2>Game Catalogue</h2>
            <p>Download game listings from Relax and selected partners</p>
            <a onClick={onDownload} className='btn btn-lg' download href={CATALOGUE_URL}>See More</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default observer(Catalogue);
