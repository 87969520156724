import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import PromoEditor from './PromoEditor';
import GamesStore from '../../../stores/GamesStore';
import {updateSlots, setSlot} from './PromoEditorContainer.actions';
import {times, equals, sortBy, prop, pick} from 'ramda';
import {CAROUSEL_PROMO_SLOTS, FEATURED_PROMO_SLOTS} from '../../../config';

class PromoEditorContainer extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    allGames: PropTypes.arrayOf(PropTypes.object).isRequired,
    store: PropTypes.instanceOf(GamesStore).isRequired
  }

  static defaultProps = {
    allGames: []
  }

  constructor(props) {
    super();

    this.state = {
      carousel: times(() => null, CAROUSEL_PROMO_SLOTS),
      featured: times(() => null, FEATURED_PROMO_SLOTS),
      options: null,
      requesting: false
    };

    this.onCallback = this.onCallback.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    const {allGames} = this.props;

    const state = await updateSlots(prevProps.allGames, this.props.allGames);
    if (state) {
      if (!equals(state, this.state)) {
        this.setState(state);
      }
    }

    if (!this.state.options && allGames && allGames.length > 0) {
      const picked = allGames.map(pick(['gameid', 'gameName']));
      const valid = picked.filter(({gameName}) => !!(gameName && gameName.trim()));
      const options = [{gameid: ' ', gameName: '[none]'}].concat(sortBy(prop('gameName'), valid));
      this.setState({options});
    }

    if (this.state.requesting && !this.props.isOpen) {
      this.setState({requesting: false});
    }
  }

  onCallback(action, params) {
    const {store} = this.props;
    switch(action) {
      case 'select':
        this.setState(setSlot(this.state, params.type, params.index, params.gameid));
        break;
      case 'save':
        this.setState({requesting: true});
        store.setPromos(this.state.carousel, this.state.featured);
        break;
      case 'cancel':
        store.setPromoEditorVisible(false);
        break;
      default:
    }
  }

  render() {
    return (
      <PromoEditor
        requesting={this.state.requesting}
        isOpen={this.props.isOpen}
        {...this.state}
        callback={this.onCallback}/>
    );
  }
}

export default observer(PromoEditorContainer);
