import {DEMOURL} from './config';
import {head, tail, reverse, last, trim, findIndex} from 'ramda';
import moment from 'moment';

export const isValidEmail = (email) => /.+@.+\..+/.test(email);

export const trimLeadingSlashes = (str) => {
  if (head(str) === '/') {
    return trimLeadingSlashes(tail(str));
  }
  return str || '';
}

export const publicUrl = (url) => {
  const urlPath = trimLeadingSlashes(url);
  const urlBase = reverse(trimLeadingSlashes(reverse(window.location.origin)));
  return [urlBase, urlPath].join('/');
}

export const demoGameUrl = (gameid, channel) => `${DEMOURL}?channel=${channel}&gameid=${gameid}&moneymode=fun&jurisdiction=MT&partnerid=10`;
export const youTubeUrl = (id) => `https://www.youtube.com/embed/${id}?rel=0`;

export const truncateText = (text, limit) => {
  const trimmed = trim((text || '').substr(0, limit));
  if (!trimmed) {
    return '';
  }

  const isDelimiter = (char) => (char === '?' || char === '!' || char === '.');
  const ellipsify = (str) => {
    const trimmed = trim(str);
    if (isDelimiter(last(trimmed))) {
      return `${trimmed} ...`
    }
    return `${trimmed}...`
  };

  const newline = trimmed.indexOf('\n\n');
  const delimiter = findIndex(isDelimiter)(trimmed.split(''));

  if (newline > 0) {
    return ellipsify(trimmed.substr(0, newline));
  }
  else if (delimiter > 0) {
    return ellipsify(trimmed.substr(0, delimiter + 1));
  }

  if (trimmed.length === limit) {
    return ellipsify(trimmed);
  }
  return trimmed;
}

const onBeforeUnload = (e) => {
  const text = 'Reloading the page will log you out';
  e.returnValue = text;
  return text;
}

export const enableUnloadConfirmation = (enable) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (enable) {
    if (!window._onBeforeUnloadListener) {
      window.addEventListener('beforeunload', onBeforeUnload);
      window._onBeforeUnloadListener = true;
    }
  }
  else if (!enable) {
    window.removeEventListener('beforeunload', onBeforeUnload);
    window._onBeforeUnloadListener = false;
  }
}

export const formatReleaseDate = (timestamp, suffix = '', now = moment()) => {
  if (timestamp) {
    const release = moment(timestamp, 'X');
    if (release.isValid()) {
      const duration = moment.duration(now.diff(release))
      if (now.year() !== release.year() || Math.abs(duration.asMonths()) > 4) {
        return release.format('Do MMM YYYY') + suffix
      }
      else {
        return release.format('Do MMMM') + suffix
      }
    }
  }

  return '';
}


export const buildShortDescription = (game, withReleaseDate = false) => {
  if (game) {
    const text = game.shortDescription || truncateText(game.description, 120);
    if (text) {
      if (withReleaseDate) {
        return formatReleaseDate(game.releaseDate, ' - ') + text;
      }
      else {
        return text;
      }
    }
  }

  return 'Description missing!'
}