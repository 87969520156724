export const list = (api, onOk, onError) => {
  const handler = api.createEndHandler('news/list', onOk, onError);
  return api.get_dst('news', 'list/all').end(handler);
}

export const upload = (api, content, onOk, onError, onProgress) => {
  const handler = api.createEndHandler('news/upload', onOk, onError);
  return api.post_mp_dst('news', 'upload', content, onProgress).end(handler);
}

export const del = (api, id, onOk, onError) => {
  const handler = api.createEndHandler('news/delete', onOk, onError);
  return api.get_dst('news', `delete/${id}`).end(handler);
}
