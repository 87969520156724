import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Container, Row, Col, Button} from 'reactstrap';
import ContentWrapper from './ContentWrapper';
import UserConsentStore from '../stores/UserConsentStore';
import {fromPairs} from 'ramda';
import StatusMessage from '../components/StatusMessage';
import {PRIVACY_URL} from '../config';

class UserConsent extends React.Component {
  constructor({api, user}) {
    super();

    this.state = {
      mailing: false,
      accept: false
    }

    this.store = new UserConsentStore(api, user);
  }

  onCheckChange = ({target}) => this.setState(fromPairs([[target.name, target.checked]]));

  onAccept = () => {
    this.store.acceptPrivacyNotice(this.state.mailing);
  }

  render() {
    const {error, consentRequired} = this.store;
    if (!consentRequired) {
      return null;
    }

    const privacyPdf = PRIVACY_URL.split('/').pop();

    return (
      <ContentWrapper>
        <Container>
          <Row className='justify-content-center'>
            {error
              ? <Col><StatusMessage error={error}/></Col>
              : <Col>
                  <h1 className='text-center'>We value your privacy</h1>
                  <div className='text-center pt-3 pb-2'>
                    <a href={PRIVACY_URL} target='_blank' rel='noopener noreferrer' className='text-info'>{privacyPdf}</a>
                  </div>
                  <div className='mt-4 mb-1'>
                    <div>
                      <input type='checkbox' name='accept' id='accept' checked={this.state.allowPromoMailing} onChange={this.onCheckChange}/>
                      <label className='ml-2' htmlFor='accept'>I have read and accept the privacy notice for client area users.</label>
                    </div>
                    <div>
                      <input type='checkbox' name='mailing' id='mailing' checked={this.state.allowPromoMailing} onChange={this.onCheckChange}/>
                      <label className='ml-2' htmlFor='mailing'>Notify me via email when new games launch, asset packs become available, new studios join Relax Gaming, and keep me informed of other relevant news.</label>
                    </div>
                  </div>
                  <div className='text-right'>
                    <Button onClick={this.onAccept} disabled={!this.state.accept}>Continue</Button>
                  </div>
                </Col>
            }
          </Row>
        </Container>
      </ContentWrapper>
    );
  }
}
UserConsent.propTypes = {
  store: PropTypes.instanceOf(UserConsentStore)
}

export default observer(UserConsent);
