import {get, post, post_mp, parseError, parseOk} from './superagent-wrapper';
import {createLogger, LOG_API} from '../logger';
import {without, pathOr, prop, mapObjIndexed, isEmpty} from 'ramda';

const API_VERSION = 1;

const logger = createLogger('API', LOG_API);

function getEnvUrl() {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:4569';
    case 'test':
      return 'test';
    case 'production':
      return '';
    default:
      return '';
  }
}

const compact = without([null, undefined, '', false, NaN]);

export function getBaseUrl(apiPath) {
  return compact([getEnvUrl(), apiPath]).join('/');
}

export function requestAPI(onOk, onError) {
  const handler = (err, res) => {
    const payLoad = parseOk(res);
    if (payLoad) {
      logger.log(JSON.stringify(payLoad));
      onOk(payLoad);
    }
    else {
      onError(parseError(err || res));
    }
  }

  const url = [getEnvUrl(), 'start'].join('/');
  get(url).end(handler);
}

export function parseRoutes(routes, apiPath) {
  const baseUrl = getBaseUrl(apiPath);
  return mapObjIndexed((value) => ([baseUrl, value].join('/')), routes)
}

/**
 *
 * @param {*} payLoad
 * @param {*} unauthCallback called when 401 error happens and should log the user out without processing the response any further
 * @param {*} okFallback called if the onOk handler returns anything else than undefined or it doesn't exist
 * @param {*} errorFallback called if the onError handler returns anything else than undefined or it doesn't exist
 */
export function createAPI(payLoad, unauthCallback, okFallback, errorFallback) {
  if (!(unauthCallback && okFallback && errorFallback)) {
    throw new Error('createAPI callbacks are required');
  }

  const apiVersion = prop('apiVersion', payLoad);
  if (API_VERSION !== apiVersion) {
    throw new Error(`Wrong API version (expected ${API_VERSION}, but got ${apiVersion})`);
  }

  const routes = parseRoutes(payLoad.routes, payLoad.apiPath);

  if (isEmpty(routes)) {
    throw new Error('Routes missing');
  }

  const makeUrl = (routeId, routeExt, dsToken) => {
    return compact([prop(routeId, routes), routeExt, dsToken]).join('/');
  }

  const log = (method, url, params) => {
    const prefix = `${method}: ${url}`;
    if (!!params) {
      logger.log(`${prefix}: ${params}`);
    }
    else {
      logger.log(prefix);
    }
  }

  function _post(routeId, routeExt, content) {
    const url = makeUrl(routeId, routeExt);
    log('POST', url, content);
    return post(url, content, 'form');
  }

  function _post_dst(routeId, routeExt, content) {
    const url = makeUrl(routeId, routeExt, this.dstoken);
    log('POST', url, content);
    return post(url, content, 'form');
  }

  function _get(routeId, routeExt, params) {
    const url = makeUrl(routeId, routeExt);
    log('GET', url, params);
    return get(url, params);
  }

  function _get_dst(routeId, routeExt, params) {
    const url = makeUrl(routeId, routeExt, this.dstoken);
    log('GET', url, params);
    return get(url, params);
  }

  function _post_mp(routeId, routeExt, content) {
    const url = makeUrl(routeId, routeExt);
    log('POST (mp)', url, content);
    return post_mp(url, content);
  }

  function _post_mp_dst(routeId, routeExt, content, onProgress) {
    const url = makeUrl(routeId, routeExt, this.dstoken);
    log('POST (mp)', url, content);
    return post_mp(url, content, onProgress);
  }

  function createEndHandler(tag, onOk, onError, ignoreAppUnauth) {
    const _onOk = (payLoad) => {
      logger.log(tag, JSON.stringify(payLoad));
      if (!onOk || onOk(payLoad) !== undefined) {
        okFallback(tag, payLoad);
      }
    }

    const _onError = (error) => {
      logger.log(tag, JSON.stringify(error));
      if (!onError || onError(error) !== undefined) {
        errorFallback(tag, error);
      }
    }

    return (err, res) => {
      const payLoad = parseOk(res);
      if (payLoad) {
        _onOk(payLoad);
        return;
      }

      const error = parseError(err || res);
      if (error) {
        if (!ignoreAppUnauth && (error.code === 401 || error.code === 'UNAUTHORIZED')) {
          unauthCallback();
          return;
        }
        _onError(error);
        return;
      }
    };
  }

  const dstoken = pathOr('', ['user', 'doubleSubmitToken'], payLoad);

  return {
    createEndHandler: createEndHandler,
    post: _post,
    post_dst: _post_dst,
    get: _get,
    get_dst: _get_dst,
    post_mp: _post_mp,
    post_mp_dst: _post_mp_dst,
    dstoken
  };
}
