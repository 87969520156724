import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ToolsStore from '../../stores/ToolsStore';
import {Container, Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import StatusMessage from '../../components/StatusMessage';
import {last} from 'ramda';
import {mailTemplate, imgTemplate} from './template/template';

const TierSelector = observer(({tier, value, onClick}) => {
  return (
    <div onClick={onClick} className={`px-2 py-1 mr-2 tier-selector tier-${tier} ${value ? 'selected' : ''}`}>
      Tier {tier} {value ? <span className='fas fa-check'/> : null}
    </div>
  );
})

const Recipients = observer(({tiers, onChange}) => {
  const onClick = (tier, value) => () => onChange(tier, !value);
  const content = tiers.map(([tier, value]) => <TierSelector key={tier} tier={tier} value={value} onClick={onClick(tier, value)}/>);
  return (
    <div className='d-flex'>
      {content}
    </div>
  );
})

const FilePicker = observer(({store, inputRef}) => {
  if (store.fileProgress) {
    return (
      <div><span>Uploading... {parseInt(store.fileProgress, 10)}%</span></div>
    );
  }

  if (store.file) {
    const {url} = store.file;
    const filename = last(url.split('/'));
    const ext = last(filename.split('.')).toLowerCase();
    if (ext === 'pdf') {
      return (
        <div><span className='fas fa-file-pdf mr-2'/>{filename}</div>
      );
    }
    else if (['jpg', 'png', 'jpeg'].indexOf(ext) !== -1) {
      return (
        <div><img style={{maxWidth: '64px'}} src={url} alt='Attachment'/></div>
      );
    }
  }

  const onChooseFile = () => {
    const file = inputRef.current.files[0];
    store.addMailAttachment(file);
  }

  return (
    <Input
      innerRef={inputRef}
      type='file'
      color='primary'
      accept='image/jpeg,image/png,image/gif,application/pdf'
      onChange={onChooseFile}/>
  );
})

class Mailing extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    tiers: PropTypes.arrayOf(PropTypes.number)
  }

  constructor({api, tiers}) {
    super();

    this.state = {
      preview: null
    };

    this.store = new ToolsStore(api, mailTemplate, imgTemplate, tiers);
    this.ref = React.createRef();
  }

  onFormChange = ({target}) => {
    this.store.update(target.name, target.value);
  }

  onTierChange = (tier, value) => {
    this.store.update(tier, value);
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.store.send();
  }

  onPreview = (event) => {
    event.preventDefault();
    this.setState({
      preview: this.store.generatePreview()
    });
  }

  onClosePreview = (event) => {
    event.preventDefault();
    this.setState({
      preview: null
    });  }

  onReset = (event) => {
    event.preventDefault();
    this.store.reset();
  }

  render() {
    const error = this.store.tiers.length > 0 ? this.store.error :
      (this.store.sending ? '' : 'Mailing not possible because no tiers have been set or no users have allowed promo mailing');

    return (
      <Container>
        <Row className='justify-content-center'>
          <Col>
            <h1 className='text-center'>Promo mailing</h1>
            <StatusMessage error={error} success={this.store.success}/>
            <Form key={this.store.key} className='mt-5 mailing' onSubmit={this.onSubmit}>
              <FormGroup>
                <Label>Recipients</Label>
                <Recipients tiers={this.store.tiers} onChange={this.onTierChange}/>
              </FormGroup>
              <FormGroup>
                <Label>Subject</Label>
                <Input
                  type='text'
                  name='subject'
                  value={this.store.subject}
                  onChange={this.onFormChange}/>
              </FormGroup>
              <FormGroup>
                <Label>Message</Label>
                <Input
                  type='textarea'
                  name='body'
                  value={this.store.body}
                  onChange={this.onFormChange}/>
              </FormGroup>
              <FormGroup>
                <Label>Greeting</Label>
                <Input
                  type='text'
                  name='greeting'
                  value={this.store.greeting}
                  onChange={this.onFormChange}/>
              </FormGroup>
              <FormGroup>
                <Label>Signature</Label>
                <Input
                  type='text'
                  name='signature'
                  value={this.store.signature}
                  onChange={this.onFormChange}/>
              </FormGroup>
              <FormGroup>
                <Label>Attachment</Label>
                <FilePicker store={this.store} inputRef={this.ref}/>
              </FormGroup>
              <FormGroup className='text-right'>
                <Button
                  size='lg'
                  className='mt-2'
                  onClick={this.onPreview}>PREVIEW</Button>
                {this.store.hasQuotes &&
                  <Button
                    size='lg'
                    className='mt-2 ml-1'
                    onClick={this.store.fixQuotes}>FIX QUOTES</Button>}
                  <Button
                  size='lg'
                  className='mt-2 ml-1'
                  disabled={!this.store.sendEnabled}
                  type='submit'>SEND</Button>
                <Button
                  size='lg'
                  className='mt-2 ml-1'
                  onClick={this.onReset}>DISCARD</Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Modal isOpen={!!this.state.preview} size='lg'>
          <ModalHeader>Preview</ModalHeader>
          <ModalBody>
            <div className='text-warning text-center p-1 mb-1'>Please note that this preview is only for reference and might not be exactly what the user will see</div>
            <div dangerouslySetInnerHTML={{__html: this.state.preview}}/>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onClosePreview}>Close</Button>
          </ModalFooter>
        </Modal>
      </Container>
    )
  }
}

export default observer(Mailing);
