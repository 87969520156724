import {splitAt, last, head, any, equals, clamp, findLastIndex, findIndex, type, insert} from 'ramda';

export const isBoundary = (text, index) => {
  const [a, b] = splitAt(index, text);
  const aa = last(a.split(''));
  const bb = head(b.split(''));
  const test = (char) => any(equals(char), [' ', '\n', '\t']);
  if (aa && bb) {
    return test(aa) || test(bb);
  }
  return true;
}

export const isDelimiter = (char) => any(equals(char), [' ', '\n', '\t', '.', ',', '\'', '"']);

export const wordStartFrom = (text, fromIndex) => {
  const [t] = splitAt(clamp(0, text.length, fromIndex), text);
  const index = findLastIndex((char) => isDelimiter(char), t.split(''));

  if (index > -1) {
    if (isDelimiter(t[index])) {
      return index + 1;
    }
    return index;
  }
  return 0;
}

export const wordEndFrom = (text, fromIndex) => {
  const [a,t] = splitAt(clamp(0, text.length, fromIndex), text);
  const index = findIndex((char) => isDelimiter(char), t.split(''));

  if (index > -1) {
    return a.length + index;
  }
  return text.length;
}

export const insertString = (index, str, target) => insert(1, str, splitAt(clamp(0, target.length, index), target)).join('');

export const addFormatToSelection = (text, selectionStart, selectionEnd, tags) => {
  if (type(text) !== 'String') {
    return {text, selectionStart, selectionEnd};
  }

  if (type(selectionStart) !== 'Number' || type(selectionEnd) !== 'Number'
    || selectionStart > selectionEnd
    || type(tags) !== 'Array' || !head(tags))
  {
    return {text, selectionStart, selectionEnd};
  }

  const start = clamp(0, selectionEnd, selectionStart);
  const end = clamp(selectionStart, text.length, selectionEnd);

  const tag1 = head(tags);
  const tag2 = tags[1] === undefined ? tag1 : tags[1];

  if (start === end) {
    if (isBoundary(text, start)) {
      const cursor = start + tag1.length;
      return {
        text: insertString(start, [tag1, tag2].join(''), text),
        selectionStart: cursor,
        selectionEnd: cursor
      }
    }
    else {
      const wordStart = wordStartFrom(text, start);
      const wordEnd = wordEndFrom(text, end);
      const a = insertString(wordEnd, tag2, text);
      const b = insertString(wordStart, tag1, a);
      const cursor = start + tag1.length;
      return {
        text: b,
        selectionStart: cursor,
        selectionEnd: cursor
      }
    }
  }
  else {
    const a = insertString(end, tag2, text);
    const b = insertString(start, tag1, a);
    return {
      text: b,
      selectionStart: start + tag1.length,
      selectionEnd: end + tag1.length
    }
  }
}
