import React from 'react';
import PropTypes from 'prop-types';

export const SingleWrapper = (props) => {
  return (
    <div className='single-wrapper' {...props}>
      {props.children}
    </div>
  );
}

const ContentWrapper = (props) => {
  const className = props.page === 'games' ? 'content-wrapper' : 'content-wrapper padded';
  return (
    <div className={className} {...props}>
      {props.children}
    </div>
  );
}
ContentWrapper.propTypes = {
  page: PropTypes.string
}

export default ContentWrapper;
