import {extendObservable, decorate, action, computed, reaction, toJS} from 'mobx';
import {list, upload, del} from '../api/News';
import {sortBy, propOr, toPairs, groupBy, remove} from 'ramda';
import moment from 'moment';
import {NEWS_DEFAULT_DAYS_SHOWN} from '../config';

export const tsByMonth = (timestamp) => {
  if (timestamp) {
    const ts = moment(timestamp, 'X');
    return moment([ts.year(), ts.month()]).unix();
  }
  return 0;
}

class NewsStore {
  constructor(api, admin, tsProvider, initialRequest = true) {
    extendObservable(this, {
      _posts: [], // all posts
      selectedMonth: null, // selected month timestamp
      selectedPost: null, // selected post id
      editId: null, // id of post being currently edited, '' if new post
      deletePost: {}, // id and title of the post being confirmed for deletion
      uploadPercent: null, // file upload progress
      all: false, // should all or just the latest posts be shown
      error: '', // error message
      success: '' // success message
    });

    if (typeof tsProvider !== 'function' || typeof tsProvider() !== 'number') {
      throw new Error('tsProvider must be a function returning a number (current unix timestamp)');
    }

    Object.defineProperty(this, 'timestamp', {
      get: function () {
        return tsProvider();
      }
    });

    this.api = api;
    this.admin = admin;

    if (initialRequest) {
      this.requestList();
    }

    // needed to 'refresh' state if you delete the only post in selected month
    this.disposePostsReaction = reaction(
      () => this.posts.length,
      (length) => {
        if (!length) {
          this.selectedMonth = null;
          this.selectedPost = null;
        }
      }
    );
  }

  unmount = () => {
    this.disposePostsReaction();
  }

  // API calls

  requestList = () => {
    list(this.api, this._onList, this._onError);
  }

  requestUpload = (content, onProgress = () => {}) => {
    if (content) {
      upload(this.api, content, this._onUpload, this._onError, onProgress);
    }
  }

  requestDelete = () => {
    if (this.deletePost.id) {
      del(this.api, this.deletePost.id, this._onDelete, this._onError);
    }
  }

  // actions

  _onList = (payLoad) => {
    this._posts = sortBy(propOr(0, 'timestamp'), payLoad).reverse();
  }

  _onUpload = (payLoad) => {
    const idx = this._updatePost(payLoad);
    this._posts = sortBy(propOr(0, 'timestamp'), this._posts).reverse()
    this.editPost(null);
    this.setStatus('', `Post '${payLoad.title}' ${idx > -1 ? 'updated' : 'added'}`);
  }

  _onProgress = ({direction, percent}) => {
    this.uploadPercent = direction === 'upload' ? percent : null
  }

  _onDelete = () => {
    const title = this.deletePost.title;
    const idx = this._posts.findIndex(({id}) => id === this.deletePost.id);
    if (idx > -1) {
      this._posts = remove(idx, 1, this._posts);
    }
    this.selectedPost = null;
    this.deletePost = {};
    this.setStatus('', title ? `Post '${title}' deleted` : '');
  }

  _updatePost = (post) => {
    const idx = this._posts.findIndex(({id}) => id === post.id);
    if (idx > -1) {
      this._posts[idx] = post;
    }
    else {
      this._posts.push(post);
    }
    return idx;
  }

  _onError = (error) => {
    this.setStatus(error.msg);
  }

  setStatus = (error, success) => {
    this.error = error || '';
    this.success = success || '';
  }

  selectMonthById = (id) => {
    const post = this.findPostById(id);
    this.selectedPost = null;
    this.selectedMonth = post ? tsByMonth(post.timestamp) : null;
    this.all = false;
  }

  selectMonthByTs = (ts) => {
    this.selectedPost = null;
    this.selectedMonth = ts ? tsByMonth(ts) : null;
    this.all = false;
  }

  selectPost = (id) => {
    const post = this.findPostById(id);
    this.selectedPost = post ? id : null;
    this.all = false;
  }

  editPost = (id) => {
    this.editId = !!this.findPostById(id) ? id : null;
    this.setStatus();
  }

  createPost = () => {
    this.editId = '';
    this.setStatus();
  }

  setDeletePost = (id) => {
    const post = this.findPostById(id);
    if (post) {
      this.deletePost = {
        id: post.id,
        title: post.title
      };
    }
    else {
      this.deletePost.id = null;
      // leave the title so the text in the popup stays while animating out
    }
    this.setStatus();
  }

  showAll = () => {
    this.selectedMonth = null;
    this.selectedPost = null;
    this.all = true;
    this.setStatus();
  }

  // util

  findPostById = (id) => this._posts.find(post => post.id === id);

  // computed

  get _availablePosts() {
    return this.admin ? this._posts : this._posts.filter(({timestamp}) => timestamp < this.timestamp);
  }

  /**
   * Currently visible posts based on admin status, selected month and selected post
   */
  get posts() {
    const availablePosts = this._availablePosts;
    if (this.selectedPost) {
      return availablePosts.filter(({id}) => id === this.selectedPost);
    }
    else if (this.selectedMonth) {
      return availablePosts.filter(({timestamp}) => tsByMonth(timestamp) === this.selectedMonth);
    }

    if (this.all) {
      return availablePosts;
    }

    const tsDaysAgo = this.timestamp - (NEWS_DEFAULT_DAYS_SHOWN * 86400);
    const postsVisibleByDays = availablePosts.filter(({timestamp}) => timestamp > tsDaysAgo);
    if (postsVisibleByDays.length < 5) {
      // if there's less than 5 posts in the last NEWS_DEFAULT_DAYS_SHOWN days, then show last 5 posts
      return availablePosts.slice(0, 5);
    }
    return postsVisibleByDays;
  }

  get monthLinks() {
    const grouped = toPairs(groupBy((post) => tsByMonth(post.timestamp), this._availablePosts));
    return grouped.map(([timestamp, data]) => {
      return {
        timestamp: parseInt(timestamp, 10),
        text: moment(timestamp, 'X').format('MMMM YYYY'),
        count: data.length
      }
    }).reverse();
  }

  /**
   * Post titles for currently selected or latest month
   */
  get postLinks() {
    const ts = tsByMonth(this.selectedMonth || this.timestamp);
    const posts = this._availablePosts.filter(({timestamp}) => tsByMonth(timestamp) === ts);
    return posts.map(({id, title}) => {
      return {
        id,
        title
      };
    });
  }

  get showAllButton() {
    return this.posts < this._availablePosts;
  }

  get editPostData() {
    return toJS(this.findPostById(this.editId));
  }
}

decorate(NewsStore, {
  _onList: action,
  _onUpload: action,
  _onProgress: action,
  _onDelete: action,
  _updatePost: action,
  _onError: action,
  setStatus: action,
  selectMonthById: action,
  selectMonthByTs: action,
  selectPost: action,
  createPost: action,
  editPost: action,
  setDeletePost: action,
  showAll: action,

  _availablePosts: computed,
  posts: computed,
  monthLinks: computed,
  postLinks: computed,
  showAllButton: computed,
  editPostData: computed
});

export default NewsStore;
