import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Container, Row, Col} from 'reactstrap';
import ContactStore from '../../stores/ContactStore';
import ContactForm from './ContactForm';
import EditContactPersons from './EditContactPersons';
import ContactPersons from './ContactPersons';
import ContentWrapper from '../ContentWrapper';
import StatusMessage from '../../components/StatusMessage';

class Contact extends React.Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    admin: PropTypes.bool.isRequired
  }

  constructor({api, admin}) {
    super();
    this.store = new ContactStore(api, admin);
  }

  render() {
    const {error, success} = this.store;
    return (
      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <StatusMessage error={error} success={success}/>
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <ContactPersons store={this.store}/>
              <EditContactPersons store={this.store}/>
            </Col>
            <ContactForm store={this.store}/>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }
}

export default observer(Contact);
