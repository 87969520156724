/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {ListGroup, ListGroupItem, Badge} from 'reactstrap';
import NewsStore from '../../stores/NewsStore';

const Month = observer(({text, count, onClick}) => {
  return (
    <ListGroupItem className='d-flex justify-content-between align-items-center'>
      <a onClick={onClick}>{text}</a>
      <Badge color='primary' pill>{count}</Badge>
    </ListGroupItem>
  );
})

const MonthPicker = ({store}) => {
  const onClick = (timestamp) => () => {
    store.selectMonthByTs(timestamp);
  }
  const content = store.monthLinks.map(({text, count, timestamp})=> (
    <Month key={text} text={text} count={count} onClick={onClick(timestamp)}/>
  ));

  return (
    <ListGroup>
      {content}
    </ListGroup>
  );
}

MonthPicker.propTypes = {
  store: PropTypes.instanceOf(NewsStore).isRequired
}

export default observer(MonthPicker);
