import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {toJS, reaction} from 'mobx';
import {Modal, ModalHeader, ModalBody, Input, ModalFooter, Button} from 'reactstrap';
import MarkdownEditor from './MarkdownEditor';
import FilePicker from './FilePicker';
import Post from '../Post';
import DateTimePicker from './DateTimePicker';
import PostEditorStore from '../../../stores/PostEditorStore';
import NewsStore from '../../../stores/NewsStore';

class PostEditor extends React.Component {
  static propTypes = {
    newsStore: PropTypes.instanceOf(NewsStore).isRequired
  }

  constructor() {
    super();
    this.store = new PostEditorStore();
  }

  componentDidMount = () => {
    this.disposeReaction = reaction(
      () => this.props.newsStore.editId,
      (editId) => {
        if (editId === '') {
          this.store.create();
        }
        else {
          const post = this.props.newsStore.findPostById(editId);
          if (post) {
            this.store.edit(post);
          }
          else {
            this.store.close();
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.disposeReaction) {
      this.disposeReaction();
      this.disposeReaction = null;
    }
  }

  onTitleChange = ({target}) => {
    this.store.setTitle(target.value);
  }

  onTimestampChange = (timestamp) => {
    this.store.setTimestamp(timestamp);
  }

  onSave = () => {
    const content = toJS(this.store.uploadContent);
    this.props.newsStore.requestUpload(content, this.store.handleProgress);
  }

  onCancel = () => {
    this.props.newsStore.editPost(null);
  }

  onCallback = (action, params) => {
    switch(action) {
      case 'markdown':
        this.store.setBody(params);
        break;
      case 'files':
        this.store.setImages(params);
        break;
      default:
    }
  }

  render() {
    const {title, body, images, timestamp, header, uploadStatus, saveDisabled, isOpen} = this.store;
    return (
      <Modal isOpen={isOpen} size='lg'>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>
        <MarkdownEditor markdown={body} callback={this.onCallback}>
          <Input type='text' placeholder='Post title' value={title} onChange={this.onTitleChange}/>
        </MarkdownEditor>
        <DateTimePicker initial={timestamp} onChange={this.onTimestampChange}/>
        <FilePicker files={images} callback={this.onCallback}/>
        <hr/>
        <div className='news-list mt-2'>
          <Post body={body} title={title} type='preview' timestamp={timestamp}/>
        </div>
      </ModalBody>
      <ModalFooter>
        <span>{uploadStatus}</span>
        <Button disabled={saveDisabled} name='savepost' color='primary' onClick={this.onSave}>Save</Button>
        <Button name='cancelpost' color='secondary' onClick={this.onCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
    );
  }
}

export default observer(PostEditor);
