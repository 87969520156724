import {extendObservable, decorate, action, computed} from 'mobx';
import {editUser} from '../api/Users';

class UserConsentStore {
  constructor(api, user) {
    extendObservable(this, {
      privacyNoticeAccepted: false,
      error: ''
    });

    this.api = api;
    this.username = user.username;
  }

  acceptPrivacyNotice = (allowPromoMailing) => {
    const user = {
      username: this.username,
      privacyNoticeAccepted: true,
      allowPromoMailing: !!allowPromoMailing
    };
    editUser(this.api, user, this._onEditUser, this._onError);
  }

  _onEditUser = (user) => {
    this.privacyNoticeAccepted = user.privacyNoticeAccepted;
    return true; // return true so okFallback handler will be called
  }

  _onError = () => {
    this.error = 'Something went horribly wrong. Please contact support.';
  }

  get consentRequired() {
    return !this.privacyNoticeAccepted;
  }
}

decorate(UserConsentStore, {
  _onEditUser: action,
  _onError: action,
  consentRequired: computed
})

export default UserConsentStore;
