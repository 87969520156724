import superagent from 'superagent';
import {last, init, toPairs, propOr, pathOr, map} from 'ramda';

const timeout = {response: 5 * 1000, deadline: 10 * 1000};
const bigTimeout = {response: 10 * 60 * 1000, deadline: 10 * 60 * 1000};

export const encodeContent = (content) => {
  if (Array.isArray(content)) {
    return content.map(i => encodeContent(i));
  }
  else if (typeof content === 'object') {
    return map(encodeContent, content);
  }
  else if (typeof content === 'string') {
    return encodeURIComponent(content);
  }
  return content;
}

export const post = (url, content, type) => superagent.post(url).timeout(timeout).withCredentials().type(type || 'form').send(encodeContent(content));
export const get = (url, params) => superagent.get(url).timeout(timeout).withCredentials().query(params);

export const post_mp = (url, content, onProgress) => {
  /**
   * Recursively add array of fields to superagent request
   * @param {*} req superagent request
   * @param {*} arr array of fields
   * @returns superagent request with fields added
   */
  const field = (req, arr) => {
    const item = last(arr || []);
    if (item) {
      return field(req.field(...item), init(arr))
    }
    return req;
  }

  /**
   * Recursively attach array of files to superagent request
   * @param {*} req superagent request
   * @param {*} arr array of files
   * @returns superagent request with fields added
   */
  const attach = (req, arr) => {
    const item = last(arr || []);
    if (item) {
      return attach(req.attach(...item), init(arr));
    }
    return req;
  }

  const fields = toPairs(propOr({}, 'fields', content));
  const files = toPairs(propOr({}, 'files', content));
  const to = files.length > 0 ? bigTimeout : timeout;
  const request = superagent.post(url).timeout(to).withCredentials().on('progress', event => {
    if (onProgress) {
      onProgress(event);
    }
  });
  // add fields
  const withFields = field(request, fields);
  // add files and return the request
  return attach(withFields, files);
}

export const parseTags = (res, idx) => {
  const tags = pathOr([], ['body', 'tags'], res);
  return idx !== undefined ? tags[idx] : tags;
}

export const parseOk = (res, tag) => {
  const tags = parseTags(res);
  if (tags[1] === 'ok') {
    if (tag && tags[0] !== tag) {
      throw new Error(`Invalid tag '${tags[0]}', expected '${tag}'`);
    }
    return res.body.payLoad;
  }
}

export const parseError = (res) => {
  if (res) {
    if (typeof res === 'string') {
      console.error(res);
      return {
        msg: res
      };
    }

    // check the res and possible containing response property for our API errors
    const {response} = res;
    if (parseTags(response || res, 1) === 'error') {
      const {payLoad} = (response || res).body;
      return {
        code: payLoad.errorcode,
        msg: payLoad.message
      };
    }

    // check the response property for http errors
    if (response) {
      // ignore redirect errors, because what to do? superagent says it automatically handles redirections
      if (response.statusType === 3) {
        console.warn('Unhandled:', response.status, response.text);
        return {
          code: response.status,
          msg: 'Unhandled redirection. Please contact support.'
        }
      }

      // 4xx and 5xx are logged to console by browser
      return {
        code: response.status,
        msg: pathOr(response.text || response.status, ['body', 'message'], response)
      };
    }

    // any error messages (timeout is handled here)
    const msg = res.message || res.errormessage || res.description || res.msg || res.text || res.status || 'Unknown error';
    console.error(res);
    if (typeof msg === 'string') {
      return {
        code: res.status,
        msg
      };
    }

    // invalid type
    console.log(res);
    if (res && res.type !== 'application/json') {
      throw new Error(`Invalid response type: ${res.type}`);
    }

    // final solution
    const e = res.status || 'Unknown error';
    return {
      code: e,
      msg: e
    }
  }
}
