import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import News from './news/News';
import NavigationContainer from './navigation/NavigationContainer';
import GamesContainer from './games/GamesContainer';
import Contact from './contact/Contact';
import MyAccount from './myaccount/MyAccount';
import Accounts from './accounts/Accounts';
import Tools from './tools/Tools';
import Footer from './Footer';
import GamesStore from '../stores/GamesStore';
import AppStore, {AppStatus} from '../stores/AppStore';
import UserConsent from './UserConsent';

const PageContent = observer(({page, subPage, appStore, gamesStore}) => {
  const {user, api, admin, navigateTo} = appStore;

  if (appStore.status === AppStatus.CONSENT_REQUIRED) {
    return (<UserConsent api={api} user={user}/>);
  }

  switch (page) {
    case 'news':
      return (<News api={api} admin={admin}/>);
    case 'games':
      return (<GamesContainer store={gamesStore} selected={subPage} navigateTo={navigateTo}/>);
    case 'account':
      return (<MyAccount api={api} user={user}/>);
    case 'accounts':
      return (<Accounts api={api} username={user.username}/>);
    case 'contact':
      return (<Contact api={api} admin={admin}/>);
    case 'tools':
      return (<Tools api={api}/>);
    default:
      return null;
  }
});

const PageContainer = ({appStore, gamesStore}) => {
  if (appStore.status > AppStatus.LOGIN) {
    const [page, subPage] = appStore.currentPage.split(':');

    ReactGA.pageview(appStore.currentPage.split(':').join('/'))

    return (
      <div>
        <NavigationContainer currentPage={page} navigateTo={appStore.navigateTo} user={appStore.user}/>
        <PageContent page={page} subPage={subPage} appStore={appStore} gamesStore={gamesStore}/>
        <Footer version={appStore.serverVersion}/>
      </div>
    );
  }

  console.warn('Something fishy might be happening here');
  return null;
}
PageContainer.propTypes = {
  appStore: PropTypes.instanceOf(AppStore).isRequired,
  gamesStore: PropTypes.instanceOf(GamesStore),
}

export default observer(PageContainer);
