import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Modal, ModalHeader, ModalBody, FormGroup, ModalFooter, Button} from 'reactstrap';
import PromoSelector from './PromoSelector';
import ProgressSpinner from '../../../components/ProgressSpinner';

const PromoEditor = (props) => {
  if (!props.options) {
    return null;
  }

  const onSave = () => props.callback('save');
  const onCancel = () => props.callback('cancel');

  const options = props.options.map((game) => (
    <option key={game.gameid} value={game.gameid}>{game.gameName}</option>
  ));

  const toPromoSelector = (key) => (gameid, index) => {
    return (
      <PromoSelector
        key={index}
        type={key}
        index={index}
        gameid={gameid}
        options={options}
        callback={props.callback}/>
    );
  };

  const carouselContent = props.carousel.map(toPromoSelector('carousel'));
  const featuredContent = props.featured.map(toPromoSelector('featured'));

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>Edit promotions</ModalHeader>
      <ModalBody>
        <FormGroup>
          <h6>Carousel</h6>
          {carouselContent}
        </FormGroup>
        <FormGroup>
          <h6>Featured</h6>
          {featuredContent}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {props.requesting && <ProgressSpinner/>}
        <Button size='md' color='primary' onClick={onSave} disabled={props.requesting}>Save</Button>
        <Button size='md' color='secondary' onClick={onCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

PromoEditor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  requesting: PropTypes.bool.isRequired,
  carousel: PropTypes.arrayOf(PropTypes.string),
  featured: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func.isRequired
}

PromoEditor.defaultProps = {
  carousel: [],
  featured: [],
  options: null,
  requesting: false
}

export default observer(PromoEditor);
