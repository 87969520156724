/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {Container, Row, Col, Carousel, CarouselItem, CarouselIndicators, CarouselControl} from 'reactstrap';
import {publicUrl, buildShortDescription} from '../../utils';
import {equals, clamp} from 'ramda';

class FeaturedCarousel extends React.Component {
  static propTypes = {
    games: PropTypes.arrayOf(PropTypes.object),
    selectGame: PropTypes.func.isRequired
  }

  constructor() {
    super();

    this.state = {
      activeIndex: 0,
      items: []
    };

    this.refreshTimer = this.refreshTimer.bind(this);
    this.move = this.move.bind(this);
    this.next = this.move(1).bind(this);
    this.previous = this.move(-1).bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.onIndexClicked = this.onIndexClicked.bind(this);
  }

  componentDidMount() {
    this.refreshItems();
    this.refreshTimer();
  }

  componentDidUpdate(prevProps) {
    if (!equals(prevProps.games, this.props.games)) {
      this.refreshItems();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  refreshTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.next, 5000);
  }

  refreshItems() {
    const {games} = this.props;
    const items = games.map((game, index) =>
      ({
        src: publicUrl(game.bannerimage || 'img/1600x755.jpg'),
        altText: `Slide ${index + 1}`,
        name: game.gameName,
        description: buildShortDescription(game),
        gameid: game.gameid
      })
    );

    this.slides = items.map((item) => {
      const onClick = (event) => {
        event.preventDefault();
        this.props.selectGame(item.gameid);
        ReactGA.event({
          category: 'Open Game',
          action: 'Carousel'
        });
      }

      return (
        <CarouselItem
          key={item.altText}
          onExiting={this.onExiting}
          onExited={this.onExited}>
          <img className='d-block w-100' src={item.src} alt={item.altText}/>
          <div className='carousel-caption'>
            <h1>{item.name}</h1>
            <p>{item.description}</p>
            <a className='btn btn-lg' onClick={onClick}>See More</a>
          </div>
        </CarouselItem>
      );
    });

    const lastIndex = items.length - 1 < 0 ? 0 : items.length - 1;
    this.setState({
      activeIndex: clamp(0, lastIndex, this.state.activeIndex),
      items
    });
  }

  move(delta) {
    return () => {
      if (!this.slides || this.animating) {
        return;
      }
      const len = this.slides.length;
      const activeIndex = (this.state.activeIndex + len + delta) % len;
      this.setState({activeIndex});
      this.refreshTimer();
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  onIndexClicked(activeIndex) {
    if (this.animating) {
      return;
    }

    this.setState({activeIndex});
    this.refreshTimer();
  }

  render() {
    const {items} = this.state;
    if (!items || items.length < 1) {
      return (<div/>);
    }

    const hideControls = items.length > 1 ? '' : 'hidden';

    const {activeIndex} = this.state;
    return (
      <div className='carousel-wrapper d-none d-md-block'>
        <Container>
          <Row>
            <Col>
              <Carousel
                className='featuredCarousel'
                next={this.next}
                previous={this.previous}
                ride='carousel'
                activeIndex={activeIndex}
                items={items}>
                <CarouselIndicators className={hideControls} items={items} activeIndex={activeIndex} onClickHandler={this.onIndexClicked}/>
                {this.slides}
                {!hideControls && <CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous}/>}
                {!hideControls && <CarouselControl direction='next' directionText='Next' onClickHandler={this.next}/>}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FeaturedCarousel;
