import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ReactGA from 'react-ga';
import {Container, Row, Col, Button, Input} from 'reactstrap';
import PromoEditorContainer from './promoeditor/PromoEditorContainer';
import FeaturedCarousel from './FeaturedCarousel';
import FeaturedItem from './FeaturedItem';
import GameDetailsContainer from './gamedetails/GameDetailsContainer';
import ContentWrapper from '../ContentWrapper';
import StatusMessage from '../../components/StatusMessage';
import RoadmapHighlights from './RoadmapHighlights';
import Roadmap from './Roadmap';
import Catalogue from './Catalogue';
import GamesStore from '../../stores/GamesStore';
import {publicUrl} from '../../utils';

const AdminButton = observer(({value, toggleFunc, options}) => {
  const color = value ? 'danger' : 'success';
  const text = value ? options[1] : options[0];
  const onClick = (event) => {
    event.preventDefault();
    toggleFunc();
  };

  return (
    <Button size='sm' className='rounded-0' color={color} onClick={onClick}>{text}</Button>
  );
})

const AdminButtons = observer(({game, store}) => {
  if (store.admin) {
    const {gameid, testBuildEnabled, oaEnabled} = game;
    return (
      <div className='text-right admin-buttons'>
        <AdminButton
          value={testBuildEnabled}
          toggleFunc={() => store.enableDemo(gameid, !testBuildEnabled)}
          options={['Enable Demo', 'Disable Demo']}/>
        <AdminButton
          value={oaEnabled}
          toggleFunc={() => store.enableGame(gameid, !oaEnabled)}
          options={['Enable', 'Disable']}/>
      </div>
    );
  }

  return null;
})

const GameItem = observer(({selectGame, game, store}) => {
  const bannerimageSmall = publicUrl(game.bannerimageSmall || 'img/810x382.jpg');

  const onClick = () => {
    selectGame(game.gameid);
    ReactGA.event({
      category: 'Open Game',
      action: 'List'
    });
  }

  return (
    <Col md={4}>
      <AdminButtons game={game} store={store}/>
      <div className='game-item' onClick={onClick}>
        <img src={bannerimageSmall} alt='Banner'/>
        <h2>{game.gameName}</h2>
      </div>
    </Col>
  );
})

const GameList = ({store, selected, selectGame}) => {
  const [selectedGameId, selectedGameMode] = (selected || '').split('/');

  const selectedGame = store.games.find(({gameid}) => gameid === selectedGameId);
  const contentWrapperStyle = selectedGame ? {display: 'none'} : {};

  const mobileItems = store.carouselGames.map((game) => (<FeaturedItem key={game.gameid} mobile game={game} selectGame={selectGame}/>));
  const featuredItems = store.featuredGames.map((game) => (<FeaturedItem key={game.gameid} game={game} selectGame={selectGame}/>));
  const filteredItems = store.filteredGames.map((game) => (<GameItem key={game.gameid} game={game} selectGame={selectGame} store={store}/>));

  return (
    <div>
      <ContentWrapper style={contentWrapperStyle} page='games'>
{/* carousel */}
        <FeaturedCarousel games={store.carouselGames} selectGame={selectGame}/>
        <div className='featured-wrapper'>
{/* mobile */}
          <Container fluid className='featured d-block d-md-none'>
            <Row>
              {mobileItems}
            </Row>
          </Container>
{/* featured */}
          <Container className='featured'>
            <Row>
              {featuredItems}
            </Row>
          </Container>
        </div>
{/* roadmap highlights */}
        <RoadmapHighlights/>
{/* roadmap */}
        <Roadmap store={store}/>
{/* catalogue */}
        <Catalogue/>
{/* all games */}
        <div className='all-games'>
          <Container>
            <Row>
              <Col className='text-center mb-5'>
                <h1>All Relax Games</h1>
                <StatusMessage error={store.error} success={store.success}/>
              </Col>
            </Row>
            <Row>
              <Col>
                {store.admin && <Button className='my-1' onClick={() => store.setPromoEditorVisible(true)}>Edit promotions</Button>}
              </Col>
              <Col className='text-center mb-5 d-flex flex-row'>
                <Input
                  type='text'
                  placeholder='Search...'
                  className='text-right'
                  onChange={({target}) => store.setFilter(target.value)}/>
                  <span className='fas fa-search my-auto ml-1 text-secondary'/>
              </Col>
            </Row>
            <Row>
              {filteredItems}
            </Row>
          </Container>
        </div>
      </ContentWrapper>
{/*single-wrapper*/}
      <GameDetailsContainer game={selectedGame} mode={selectedGameMode} store={store} selectGame={selectGame}/>
{/*promoeditor*/}
      <div>
        <PromoEditorContainer
          isOpen={store.promoEdit}
          allGames={store.games}
          store={store}/>
      </div>
    </div>
  );
}

GameList.propTypes = {
  store: PropTypes.instanceOf(GamesStore).isRequired,
  selected: PropTypes.string,
  selectGame: PropTypes.func.isRequired
}

export default observer(GameList);
