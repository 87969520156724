import {extendObservable, decorate, action, computed} from 'mobx';
import {promomail, promomailUpload} from '../api/PromoMail';
import Users from '../api/Users';
import {uniq} from 'ramda';

class ToolsStore {
  constructor(api, mailTemplate, imgTemplate, initialRequest = true) {
    extendObservable(this, {
      _tiers: null,
      key: 0,
      subject: '',
      body: '',
      greeting: '',
      signature: '',
      file: null,
      fileProgress: null,
      error: '',
      success: '',
      sending: false
    });

    this.api = api;
    this.mailTemplate = mailTemplate;
    this.imgTemplate = imgTemplate;

    if (initialRequest) {
      this.requestUserList();
    }
  }

  requestUserList = () => {
    this.sending = true;
    Users.list(this.api, this._onUserList, this._onError);
  }

  send = () => {
    this.sending = true;
    const content = this.requestContent;
    promomail(this.api, content, this._onSendMail, this._onError);
  }

  addMailAttachment = (file) => {
    if (file.size > 5 * 1024 * 1024) {
      this.reset(true);
      this.setStatus('Attachment is too big (max. 5MB allowed).');
    }
    else {
      this.sending = true;
      promomailUpload(this.api, file, this._onAddMailAttachment, this._onError, this._onFileProgress);
    }
  }

  reset = (fileOnly = false) => {
    if (!fileOnly) {
      this.subject = '';
      this.body = '';
      for (const i of this._tiers.keys()) {
        this._tiers.set(i, false);
      }
      this.greeting = '';
      this.signature = '';
    }
    this.file = null;
    this.fileProgress = null;
    this.sending = false;
    this.error = '';
    this.success = '';
    this.key++;
  }

  _onUserList = (payLoad) => {
    this.sending = false;
    const tiers = uniq((payLoad || [])
      .filter(u => u.allowPromoMailing && u.promoMailGroup && u.promoMailGroup > 0)
      .map(u => [u.promoMailGroup, false])
      .sort()
    );
    this._tiers = new Map(tiers);
  }

  _onSendMail = () => {
    this.sending = false;
    const subject = this.subject;
    this.reset();
    this.setStatus('', `Mail '${subject}' sent succesfully`);
  }

  _onAddMailAttachment = ({
    attachment,
    attachmentStatus,
    id
  }) => {
    this.sending = false;
    if (attachmentStatus === 'ok') {
      this.file = {
        url: attachment.replace(/static_assets/, 'https://clientarea.relax-gaming.com'),
        id
      };
      this.setStatus('', 'File uploaded');
    }
    else {
      this.file = null;
      this.setStatus('File upload failed. Please contact support.');
    }
  }

  _onFileProgress = ({
    direction,
    percent
  }) => {
    this.fileProgress = direction === 'upload' ? percent : null
  }

  _onError = (error) => {
    this.sending = false;
    this.setStatus(error.msg);
  }

  setStatus = (error, success) => {
    this.error = error || '';
    this.success = success || '';
  }

  update = (key, value) => {
    if (key === 'body' ||
        key === 'subject' ||
        key === 'signature' ||
        key === 'greeting') {

      this[key] = value;
    }
    else if (Number.isInteger(key)) {
      this._tiers.set(key, value);
    }

    this.setStatus();
  }

  fixQuotes = () => {
    const replaceQuotes = (str) => str.replace(/'/g, '’').replace(/"/g, '”');
    this.subject = replaceQuotes(this.subject);
    this.body = replaceQuotes(this.body);
    this.greeting = replaceQuotes(this.greeting);
    this.signature = replaceQuotes(this.signature);
  }

  generatePreview = () => {
    const img = this.file ? this.imgTemplate.replace(/{{bodyImage}}/g, this.file.url) : '';
    return this.mailTemplate
      .replace(/{{subjectText}}/g, this.subject.trim())
      .replace(/{{bodyText}}/g, this.body.trim())
      .replace(/{{greeting}}/g, this.greeting.trim())
      .replace(/{{signature}}/g, this.signature.trim())
      .replace(/{{bodyImage}}/g, img);
  }

  get tiers() {
    return !!this._tiers ? [...this._tiers.entries()] : [];
  }

  get promoMailGroups() {
    return this.tiers.filter(([, value]) => !!value).map(([tier]) => tier);
  }

  get sendEnabled() {
    return !this.sending && this.promoMailGroups.length > 0 && !!this.subject.trim() && !!this.body.trim() && !!this.signature.trim();
  }

  get requestContent() {
    return {
      promoMailGroups: this.promoMailGroups,
      subject: this.subject.trim(),
      body: this.body.trim(),
      greeting: this.greeting.trim(),
      signature: this.signature.trim(),
      id: this.file ? this.file.id : undefined
    };
  }

  get hasQuotes() {
    const str = [this.subject, this.body, this.greeting, this.signature].join('');
    return str.indexOf('\'') > -1 || str.indexOf('"') > -1;
  }
}
decorate(ToolsStore, {
  requestUserList: action,
  send: action,
  addMailAttachment: action,
  reset: action,
  _onUserList: action,
  _onSendMail: action,
  _onAddMailAttachment: action,
  _onFileProgress: action,
  _onError: action,
  setStatus: action,
  update: action,
  fixQuotes: action,
  tiers: computed,
  promoMailGroups: computed,
  sendEnabled: computed,
  requestContent: computed,
  hasQuotes: computed
})

export default ToolsStore;
