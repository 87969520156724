import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';

const PromoSelector = (props) => {
  const onChange = ({target}) => {
    props.callback('select', {
      type: props.type,
      index: props.index,
      gameid: target.value
    });
  }

  return (
    <Input type='select' value={props.gameid || ''} name='select' onChange={onChange}>
      {props.options}
    </Input>
  );
}

PromoSelector.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  gameid: PropTypes.string,
  options: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired
}

export default PromoSelector;
