import {extendObservable, decorate, action, computed} from 'mobx';
import moment from 'moment';

export const validateDate = (date) => {
  const m = moment(date, 'D.M.YYYY', true);
  return m.isValid() ? m.unix() : null;
}

export const validateTime = (time) => {
  const m = moment(time, 'H:mm', true);
  return m.isValid() ? m.unix() : null;
}

const getTimestamp = () => {
  return 0;
}

class DateTimeStore {
  constructor(initial = null, tsProvider = getTimestamp) {
    extendObservable(this, {
      date: '',
      time: '',
      initial: initial
    });

    if (typeof tsProvider !== 'function' || typeof tsProvider() !== 'number') {
      throw new Error('tsProvider must be a function returning a number (current unix timestamp)');
    }

    Object.defineProperty(this, 'currentTimestamp', {get: function () { return tsProvider(); }});

    this.setDefaults();
  }

  setDefaults = () => {
    if (this.initial) {
      const m = moment(this.initial, 'X');
      this.date = m.format('D.M.YYYY');
      this.time = m.format('H:mm')
    }
    else {
      this.date = '';
      this.time = '';
    }
  }

  fillDate = () => {
    if (this.date) {
      const now = moment(this.currentTimestamp, 'X').format('D.M.YYYY').split('.');
      const date = this.date.split('.');
      this.date = now.map((val, idx) => date[idx] || val).join('.');
    }
  }

  fillTime = () => {
    if (this.time) {
      const time = this.time.split(':');
      this.time = ['12', '00'].map((val, idx) => time[idx] || val).join(':');
    }
  }

  setDate = (date) => {
    this.date = date.trim().replace(/[^\d./]/g, '').replace(/[/]/g, '.').substring(0, 10);
  }

  setTime = (time) => {
    this.time = time.trim().replace(/[^\d.:-]/g, '').replace(/[/]/g, ':').substring(0, 5);
  }

  reset = () => {
    this.date = '';
    this.time = '';
  }

  get timestamp() {
    const d = validateDate(this.date);
    if (d) {
      const t = validateTime(this.time);
      if (t) {
        const m = moment(`${this.date} ${this.time}`, 'D.M.YYYY H:mm', true);
        if (m.isValid()) {
          return m.unix();
        }
      }
      return d;
    }
    return null;
  }

  get timeEditable() {
    return this.date !== '' && validateDate(this.date);
  }

  get invalid() {
    if (this.date !== '') {
      if (!validateDate(this.date)) {
        return 'Invalid date';
      }
      if (this.time !== '' && !validateTime(this.time)) {
        return 'Invalid time';
      }
    }
    return '';
  }
}
decorate(DateTimeStore, {
  setDefaults: action,
  fillDate: action,
  fillTime: action,
  setDate: action,
  setTime: action,
  reset: action,
  timestamp: computed,
  timeEditable: computed,
  invalid: computed
})

export default DateTimeStore;