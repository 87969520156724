import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import ImageCarousel from '../../components/ImageCarousel';
import moment from 'moment';

const Header = ({type, timestamp, title, body}) => {
  const getDate = (type, timestamp, title, body) => {
    if (!(title || body)) {
      return null;
    }

    const date = (timestamp && timestamp > 0) ? moment(timestamp, 'X').format('LL') : moment().format('LL') ;
    return (<div className='date'>{date}</div>);
  }

  const date = getDate(type, timestamp, title, body);
  return (
    <div>
      {date}
      <h2>{title}</h2>
    </div>
  );
}

const Content = ({body, type}) => {
  if (type === 'preview') {
    if (!body) {
      return (<p className='text-muted'>Post preview</p>)
    }
  }

  if (body) {
    return (<ReactMarkdown disallowedTypes={['image', 'imageReference', 'heading']} source={body}/>);
  }

  return null;
}

const Images = ({type, images}) => {
  if (!(images && images.length)) {
    return null;
  }

  switch(type) {
    case 'archive':
      return (<ImageCarousel urls={[images[0]]} alt='Image' controls={false}/>);
    case 'single':
      return (<ImageCarousel urls={images} alt='Image' controls={false}/>);
    case 'preview':
    default:
      return null;
  }
}

const Post = (props) => {
  const {type} = props;
  const itemProps = (type === 'archive')
    ? {onClick: props.onClick, style: {cursor: 'pointer'}}
    : {};

  const className = ['news-item', type].join(' ');

  return (
    <div className={className} {...itemProps} >
      <Images {...props}/>
      <Header {...props}/>
      <Content {...props}/>
      {props.children}
    </div>
  );
}

Post.propTypes = {
  type: PropTypes.oneOf(['preview', 'archive', 'single']),
  title: PropTypes.string,
  timestamp: PropTypes.number,
  body: PropTypes.string,
  images: PropTypes.array
}

export default Post;
