import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input, FormFeedback, Button, Row, Col} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import {fromPairs, omit, pickBy, last, head} from 'ramda';
import {youTubeUrl} from '../../../utils';
import 'flatpickr/dist/themes/material_green.css'

/*
Note: the peculiar handling of videoURL as id in this component is
because the server chokes on url containing '='.

So we show whatever user has copy/pasted in the field, but we store
only the id on the server. When coming back next time, we show the
youtube embed url instead of whatever the user typed before
*/

const parseIdFromURL = (url) => {
  // our regexp doesn't know how to handle embed url
  if (url.indexOf('embed') > url.indexOf('youtube.com')) {
    const l = last(url.split('/'));
    return (l && head(l.split('?'))) || '';
  }

  const rid = (
    /\s*[a-zA-Z//:.]*youtu(be.com\/watch\?v=|.be\/)([a-zA-Z0-9\-_]+)([a-zA-Z0-9/*\-_?&;%=.]*)/i
    .exec(url || '') || []
  )[2];
  if (rid) {
    return rid;
  }

  return '';
}

const detailKeys = [
  'description',
  'shortDescription',
  'relaxContact',
  'studioContact',
  // videoURL is handled in updateVideoFromProps
  'gameType',
  'rtp',
  'rngType',
  'payoutMechanic',
  'volatility',
  'maxWinPerLine',
  'maxCoincidingWin',
  'maxWin',
  'hitFrequency',
  'avgWin',
  'minBet',
  'maxBet',
  'releaseDate'
];

class DetailsForm extends React.Component {
  static propTypes = {
    game: PropTypes.object,
    onSave: PropTypes.func.isRequired
  }

  constructor({game}) {
    super();

    this.state = {
      ...fromPairs(detailKeys.map(key => [key, game[key] || ''])),
      ...{
        videoURLInput: '',
        videoID: '',
        statusMessage: ''
      }
    }
  }

  componentDidMount() {
    this.updateVideoFromProps();
    this.initialState = Object.assign({}, this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    const {videoURL} = this.props.game;
    if (videoURL && prevProps.game.videoURL !== videoURL) {
      this.updateVideoFromProps();
    }

    const {videoURLInput} = this.state;
    if (prevState.videoURLInput !== videoURLInput) {
      this.setState({
        videoID: parseIdFromURL(videoURLInput)
      });
    }
  }

  updateVideoFromProps() {
    // videoURL is really a video ID, but in the input field we should show url
    const id = this.props.game.videoURL;
    if (id) {
      this.setState({
        videoURLInput: youTubeUrl(id),
        videoID: id
      });
    }
  }

  onChange = ({target}) => {
    this.setState(fromPairs([[target.name, target.value]]));
    this.updateStatus();
  }

  onReleaseDateChange = (_, dateStr) => {
    this.setState({'releaseDate': dateStr});
    this.updateStatus();
  }

  onSave = (event) => {
    event.preventDefault();
    const videoURL = this.state.videoID || '';
    const data = pickBy((val, key) => this.props.game[key] !== val, {
      ...omit(['videoURLInput', 'videoID'], this.state)
    });

    this.props.onSave({
      ...data,
      ...{
        videoURL,
        gameid: this.props.game.gameid
      },
    });

    this.initialState = Object.assign({}, this.state);
    this.updateStatus('Saved!');
  }

  onDiscard = (event) => {
    event.preventDefault();
    this.setState(fromPairs(detailKeys.map(key => [key, this.props.game[key] || ''])), this.updateVideoFromProps);
    this.initialState = Object.assign({}, this.state);
    this.updateStatus();
  }

  updateStatus = (msg = '') => {
    if (this.statusMessage !== msg) {
      this.setState({ statusMessage: msg });
    }
  }

  render() {
    const invalidVideoUrl = !!(this.state.videoURLInput && !this.state.videoID);
    const overviewFields = [
      ['Game type',                'gameType'],
      ['RTP',                      'rtp'],
      ['Format',                   'rngType'],
      ['Payout mechanic',          'payoutMechanic'],
      ['Volatility',               'volatility'],
      ['Max win per line',         'maxWinPerLine'],
      ['Max coinciding win',       'maxCoincidingWin'],
      ['Max win (sim)',            'maxWin'],
      ['Hit frequency',            'hitFrequency'],
      ['Average win (free-spins)', 'avgWin'],
      ['Min bet',                  'minBet'],
      ['Max bet',                  'maxBet']
    ].map(([placeholder, key]) => (
      <Col key={key} xs={12} sm={6} xl={4} className='p-0'>
        <Input type='text' name={key} placeholder={placeholder} value={this.state[key] || ''} onChange={this.onChange}/>
      </Col>
    ));

    const flatpickrOptions = {
      altFormat: "d.m.Y H:i",
      altInput: true,
      enableTime: true,
      time_24hr: true,
      dateFormat: 'U'
    }

    return (
      <FormGroup className='asset-editor-form'>
        <Input
          type='textarea'
          name='description'
          value={this.state.description}
          placeholder='Game description'
          onChange={this.onChange}/>
        <Input
          type='text'
          name='shortDescription'
          value={this.state.shortDescription}
          className='mt-2'
          placeholder='Short description for featured/promotions'
          onChange={this.onChange}/>
        <Flatpickr
          options={flatpickrOptions}
          value={this.state.releaseDate}
          className="mt-2 form-control"
          placeholder='Release date'
          onChange={this.onReleaseDateChange}/>
        <Input
          type='text'
          name='relaxContact'
          value={this.state.relaxContact}
          className='mt-2'
          placeholder='Relax contact e-mail'
          onChange={this.onChange}/>
        <Input
          type='text'
          name='studioContact'
          value={this.state.studioContact}
          className='mt-2'
          placeholder='Studio contact e-mail'
          onChange={this.onChange}/>
        <FormGroup className='m-0'>
          <Input
            type='text'
            name='videoURLInput'
            value={this.state.videoURLInput}
            className='mt-2'
            placeholder='YouTube video URL'
            invalid={invalidVideoUrl}
            onChange={this.onChange}/>
          <FormFeedback>Invalid video URL</FormFeedback>
        </FormGroup>
        <FormGroup><Row className='mx-0 mt-2'>
          {overviewFields}
        </Row></FormGroup>
        <div className='w-100 text-right'>
          <div className="text text-success">{this.state.statusMessage}&nbsp;</div>
          <Button size='lg' color='success' className='mt-2 ml-2' onClick={this.onSave}>Save</Button>
          <Button size='lg' color='danger' className='mt-2 ml-2' onClick={this.onDiscard}>Discard</Button>
        </div>
      </FormGroup>
    );
  }
}

export default DetailsForm;
