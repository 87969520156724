import {extendObservable, decorate, action, computed} from 'mobx';
import {editUser, resetUserPw} from '../api/Users';
import {isValidEmail} from '../utils';

class MyAccountStore {
  constructor(api, user) {
    extendObservable(this, {
      callname: user.callname || '',
      email: user.email || '',
      allowPromoMailing: !!user.allowPromoMailing,
      dialogOpen: false,
      error: '',
      success: ''
    });

    this.user = user;
    this.api = api;
  }

  // API calls

  requestSave = () => {
    const onEditUser = action(() => {
      this.setStatus('', 'User edited succesfully');
      // return true so the App.okFallback handler will be called
      return true;
    });

    const user = this.requestContent;
    editUser(this.api, user, onEditUser, this.onError);
  }

  requestResetPw = () => {
    const onResetPw = action(() => {
      this.dialogOpen = false;
      this.setStatus('', 'User password reset succesfully');
      // TODO: should we log out here?
    })
    resetUserPw(this.api, this.user.username, onResetPw, this.onError);
  }

  onError = (error) => {
    this.setStatus(error.msg);
  }

  setStatus = (error, success) => {
    this.error = error || '';
    this.success = success || '';
  }

  // store handling

  update = (key, value) => {
    if (key === 'callname' || key === 'email' || key === 'allowPromoMailing') {
      this[key] = value;
      this.setStatus('', '');
    }
  }

  showDialog = (show) => {
    this.dialogOpen = !!show;
  }

  // computed values

  get validEmail() {
    return isValidEmail(this.email);
  }

  get saveDisabled() {
    return !(this.callname && this.email)
      || !this.validEmail
      || !(this.callname.trim() && this.email.trim());
  }

  get requestContent() {
    return {
      username: this.user.username,
      callname: this.callname.trim(),
      email: this.email.trim(),
      allowPromoMailing: !!this.allowPromoMailing
    };
  }
}

decorate(MyAccountStore, {
  requestSave: action,
  requestChangePw: action,

  onError: action,
  setStatus: action,

  update: action,
  showDialog: action,

  validEmail: computed,
  saveDisabled: computed,
  requestContent: computed
})

export default MyAccountStore;
