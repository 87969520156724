export const promomail = (api, content, onOk, onError) => {
  const handler = api.createEndHandler('promomail', onOk, onError);
  return api.post_dst('promomail', null, JSON.stringify(content)).end(handler);
}

export const promomailUpload = (api, file, onOk, onError, onProgress) => {
  const handler = api.createEndHandler('promomail/upload', onOk, onError);
  const content = {
    files: [file]
  };
  return api.post_mp_dst('promomail', 'upload', content, onProgress).end(handler);
}
