import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Button, FormFeedback} from 'reactstrap';
import {fromPairs} from 'ramda';

const Feedback = ({invalid, length}) => {
  if (invalid) {
    if (length < 6) {
      return (<FormFeedback>Password must be at least 6 characters long</FormFeedback>);
    }
    return (<FormFeedback>Password contains invalid characters</FormFeedback>);
  }
  return null;
}

const ConfirmFeedback = ({invalid, length}) => {
  if (invalid) {
    if (length > 0) {
      return (<FormFeedback>Passwords don't match</FormFeedback>);
    }
  }
  return null;
}

class ChangePasswordForm extends React.Component {
  static propTypes = {
    username: PropTypes.string,
    callback: PropTypes.func.isRequired,
    requesting: PropTypes.bool.isRequired
  }

  constructor(props) {
    super();

    this.state = {
      password: '',
      password2: ''
    };

    this.passwordRef = React.createRef();
    this.buttonRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.passwordRef.current) {
      if (!this.state.password && !this.state.password2) {
        this.passwordRef.current.focus();
      }
    }
  }

  onInputChange = ({target}) => {
    this.setState(fromPairs([[target.name, target.value]]));
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.submit();
  }

  submit() {
    const {current} = this.buttonRef;
    if (current && !current.props.disabled) {
      this.props.callback('changepw', {
        username: this.props.username,
        password: this.state.password
      });
    }
  }

  render() {
    const {password, password2} = this.state;
    const invalidPassword = !/^[ -~]{6,}$/.test(password);
    const invalidPassword2 = password !== password2;
    const disabled = this.props.requesting;

    return (
      <Form onSubmit={this.onSubmit}>
        <Input
          disabled
          type='text'
          id='inputUsername'
          autoComplete='username'
          className='mb-3'
          value={this.props.username}/>
        <Input
          innerRef={this.passwordRef}
          disabled={disabled}
          invalid={invalidPassword}
          type='password'
          id='inputPassword'
          name='password'
          placeholder='Password'
          autoComplete='new-password'
          required
          autoFocus
          value={password}
          className='mb-1'
          onChange={this.onInputChange}/>
        <Feedback invalid={invalidPassword} length={password.length}/>
        <Input
          disabled={disabled || invalidPassword}
          invalid={invalidPassword2}
          type='password'
          id='inputPassword2'
          name='password2'
          placeholder='Confirm password'
          autoComplete='new-password'
          required
          value={password2}
          onChange={this.onInputChange}/>
        <ConfirmFeedback invalid={invalidPassword2} length={password2.length}/>
          <Button
            ref={this.buttonRef}
            className='mt-2'
            color='primary'
            size='lg'
            block
            disabled={disabled || invalidPassword || invalidPassword2}>OK</Button>
      </Form>
    );
  }
}

export default ChangePasswordForm;
