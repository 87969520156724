/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Row, Col, Modal, ModalHeader, ModalBody, ButtonGroup, Input, Button} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {addFormatToSelection} from './MarkdownEditor.functions';

const Help = (props) => {
  const data = [
    'Headings are not allowed, use **bold** if needed. If you need the hash mark, escape it with backslash: \\#',
    '*italic*',
    '**bold**',
    '***bold italic***',
    '~~strikethrough~~',
    '[Links](http://www.relax-gaming.com) are allowed',
    '`preformatted/monospace` with backticks'
  ];

  const HelpRow = ({content}) => {
    return (
      <Row className='border-top'>
        <Col className='mt-2'>{content}</Col>
        <Col className='mt-2'><ReactMarkdown disallowedTypes={['image', 'imageReference', 'heading']} source={content}/></Col>
      </Row>
    );
  };

  const onClose = (event) => {
    event.preventDefault();
    props.onClose();
  }

  const content = data.map((value, index) => <HelpRow key={index} content={value}/>);

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>Markdown help
        <a className='single-close rounded-top-right' onClick={onClose}>×</a>
      </ModalHeader>
      <ModalBody>
        <Row className='text-center mt-2'><Col><h6>Markdown</h6></Col><Col><h6>Output</h6></Col></Row>
        {content}
      </ModalBody>
    </Modal>
  );
}

class MarkdownEditor extends React.Component {
  static propTypes = {
    markdown: PropTypes.string,
    callback: PropTypes.func.isRequired
  }

  static defaultProps = {
    markdown: ''
  }

  constructor(props) {
    super();

    this.state = {
      markdown: props.markdown,
      help: false
    };

    this.inputRef = React.createRef();

    this.onFormatButton = this.onFormatButton.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {markdown} = this.state;
    if (markdown !== prevState.markdown) {
      this.props.callback('markdown', markdown);
    }

    if (prevProps.markdown !== this.props.markdown) {
      this.setState({markdown: this.props.markdown});
    }
  }

  onFormatButton = (tags) => (event) => {
    const input = this.inputRef.current;
    if (input) {
      const {selectionStart, selectionEnd, value} = input;
      const markdown = value || '';
      const res = addFormatToSelection(markdown, selectionStart, selectionEnd, tags);
      this.setState({markdown: res.text}, () => {
        input.focus();
        input.selectionStart = res.selectionStart;
        input.selectionEnd = res.selectionEnd;
      });
    }
  }

  onChange({target}) {
    this.setState({markdown: target.value});
  }

  render() {
    const {markdown, help} = this.state;
    return (
      <div>
        <div className='my-1'>
          <ButtonGroup>
            <Button size='sm' onClick={this.onFormatButton(['**'])}><span className='fas fa-bold'/></Button>
            <Button size='sm' onClick={this.onFormatButton(['*'])}><span className='fas fa-italic'/></Button>
            <Button size='sm' onClick={this.onFormatButton(['~~'])}><span className='fas fa-strikethrough'/></Button>
          </ButtonGroup>
          <ButtonGroup className='ml-1'>
            <Button size='sm' onClick={this.onFormatButton(['[', '](http://url)'])}><span className='fas fa-link'/></Button>
            <Button size='sm' onClick={this.onFormatButton(['```\n', '\n```'])}>pre</Button>
          </ButtonGroup>
          <Button size='sm' className='float-right' onClick={() => this.setState({help: true})}><span className='fas fa-question'/></Button>
        </div>
        {this.props.children}
        <div className='mb-1'>
          <Input
            innerRef={this.inputRef}
            value={markdown}
            placeholder='Post content'
            type='textarea'
            onChange={this.onChange}/>
        </div>
        <Help isOpen={help} onClose={() => this.setState({help: false})}/>
      </div>
    );
  }
}

export default observer(MarkdownEditor);
